import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert, FormGroup, Label, Input, Progress, Row, Col } from 'reactstrap'
import moment from 'moment'

const GET_BLOCKED_CUSTOMER_QUERY = gql`
  query GetBlockedCustomer($id: ID!) {
    blockedCustomer(id: $id) {
        id
        notes
        isArchived
        archivedDate
        dateAdded
        archivedBy {
            id
            username
            firstName
            lastName
        }
        blockedBy {
            id
            username
            firstName
            lastName
        }
        reason {
            id
            name
            description
        }
    }
  }
`

const UPDATE_BLOCKED_CUSTOMER_MUTATION = gql`
    mutation UpdateBlockCustomer($input: UpdateBlockCustomerMutationInput!) {
        updateBlockCustomer(input: $input) {
            ok
            errors {
                field
                messages
            }
        }
    }
`

const UpdateDNR = ({ client, blockedCustomerId, open, handleClose, refetchQuery }) => {
    const [input, setInput] = useState({
        blockedCustomerId: blockedCustomerId,
        notes: "",
        isArchived: false
    })
    const [blockedCustomer, setBlockedCustomer] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    // Load the initial data
    useEffect(() => {
        if (open && blockedCustomerId) {
            setLoading(true)
            client.query({
                query: GET_BLOCKED_CUSTOMER_QUERY,
                variables: { id: blockedCustomerId }
            }).then(response => {
                const { blockedCustomer } = response.data
                if (blockedCustomer) {
                    setBlockedCustomer(blockedCustomer)
                    setInput({
                        blockedCustomerId: blockedCustomer.id,
                        notes: blockedCustomer.notes,
                        isArchived: blockedCustomer.isArchived
                    })
                }
                setLoading(false)
            }).catch(err => {
                setError(err.toString())
                setLoading(false)
            })
        }
    }, [client, blockedCustomerId, open])

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_BLOCKED_CUSTOMER_MUTATION,
                variables: { input }
            })
            if (data.updateBlockCustomer.ok) {
                refetchQuery()
                handleClose()
            } else if (data && data.updateBlockCustomer && data.updateBlockCustomer.errors && data.updateBlockCustomer.errors.length > 0) {
                setError(data.updateBlockCustomer.errors[0].messages[0])
                setLoading(false)
            }
        } catch (err) {
            setError(err.message || "An error occurred")
        }
        setLoading(false)
    }

    return (
        <Modal isOpen={open} className="dnr-modal" toggle={handleClose}>
            <ModalHeader>Update Blocked Customer</ModalHeader>
            <ModalBody>
                {loading && <Row><Col><Progress animated color="info" value="100" /></Col></Row>}
                {error && <Row><Col><Alert color="danger">{error}</Alert></Col></Row>}
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="blockReason">Block Reason</Label>
                            <Input type="text" name="blockReason" id="blockReason" disabled value={blockedCustomer && blockedCustomer.reason ? blockedCustomer.reason.name + " - " + blockedCustomer.reason.description : "--"} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Date Added</Label>
                            <Input type="datetime-local" name="dateAdded" id="dateAdded" disabled value={blockedCustomer ? moment(blockedCustomer.dateAdded).format("YYYY-MM-DDTHH:mm") : ""} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Archive Date</Label>
                            <Input type="date" name="archivedDate" id="archivedDate" disabled value={blockedCustomer ? blockedCustomer.archivedDate : null} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="archivedBy">Archived By</Label>
                            <Input
                                type="text"
                                name="archivedBy"
                                disabled
                                value={blockedCustomer && blockedCustomer.archivedBy ? blockedCustomer.archivedBy.firstName ? blockedCustomer.archivedBy.firstName + " " + blockedCustomer.archivedBy.lastName : blockedCustomer.archivedBy.username : "--"}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="blockedBy">Blocked By</Label>
                            <Input
                                type="text"
                                name="blockedBy"
                                disabled
                                value={blockedCustomer && blockedCustomer.blockedBy ? blockedCustomer.blockedBy.firstName ? blockedCustomer.blockedBy.firstName + " " + blockedCustomer.blockedBy.lastName : blockedCustomer.blockedBy.username : "--"}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="notes">Notes</Label>
                            <Input
                                type="textarea"
                                name="notes"
                                value={input.notes}
                                onChange={(e) => setInput({ ...input, notes: e.target.value })}
                                placeholder="Enter notes"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={input.isArchived}
                                    onChange={(e) => setInput({ ...input, isArchived: e.target.checked })}
                                />{' '}
                                Is Archived
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleClose}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit} disabled={loading}>
                    Update
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default withApollo(UpdateDNR)