import React from "react"
import { Col, Row, FormGroup, Label } from "reactstrap"
import DocumentDataInput from "../DocumentDataInput/DocumentDataInput"
import DocumentDataSelect from "../DocumentDataInput/DocumentDataSelect"
import { groupFieldsByCategory, categoryNameMapping } from "../DocumentModal/utils"


const DocumentFields = ({ documentTypeDocumentData, allInputsFilled, handleFieldChange }) => {
    const countryField = documentTypeDocumentData && documentTypeDocumentData.find(item => item.fieldCode.startsWith("COUNTRY"))
    const countryCode = countryField ? countryField.value : undefined
    const stateField = documentTypeDocumentData && documentTypeDocumentData.find(item => item.fieldCode.startsWith("STATE"))
    const stateCode = stateField ? stateField.value : undefined
    const groupedFields = groupFieldsByCategory(documentTypeDocumentData || [])

    const isSelectField = (fieldCode) => {
        const prefixes = ["COUNTRY", "STATE", "CITY", "GENDER", "PLATFORM"]
        return prefixes.some((prefix) => fieldCode.startsWith(prefix))
    }

    return (
        <Col xs={12} style={{ marginTop: "20px" }}>
            {!allInputsFilled && <Label for="documentDataInputs">Please complete all required fields</Label>}
            {Object.keys(groupedFields).map((category) => {
                const categoryName = categoryNameMapping[category] || ""
                return (
                    <FormGroup key={category}>
                        <Label className="document-category-label">{categoryName}</Label>
                        <Row style={{ marginBottom: "10px" }}>
                            {groupedFields[category].map((field, index) => (
                                <Col className="data-field" xs={4} key={index}>
                                    <Label>{field.displayName.toUpperCase() || ""}</Label>
                                    {isSelectField(field.fieldCode) ? (
                                        <DocumentDataSelect field={field} handleDriverDataChange={handleFieldChange} countryCode={countryCode} stateCode={stateCode} />
                                    ) : (
                                        <DocumentDataInput field={field} handleDriverDataChange={handleFieldChange} />
                                    )}
                                </Col>
                            ))}
                        </Row>
                    </FormGroup>
                )
            })}
        </Col>
    )
}

export default DocumentFields
