import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Alert, Progress } from "reactstrap"
import Select from "react-select"
import gql from "graphql-tag"
import { withApollo } from "react-apollo"
import renderEnumToInt from "../../../Functions/renderEnumToInt"

const BusinessHourDayOfWeek = gql`
  query BusinessHourDayOfWeek {
    dataList: __type(name: "BusinessHourDayOfWeek") {
      edges: enumValues {
        name
        description
      }
    }
  }
`
const CreateBusinessHour = gql`mutation CreateBusinessHourInput($input: CreateBusinessHourInput!){
    createBusinessHour(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateBusinessHour = gql`mutation UpdateBusinessHourInput($input: UpdateBusinessHourInput!){
    updateBusinessHour(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const BusinessHoursModal = ({ client, isOpen, toggle, businessHours, isUpdate, branchId, refetch }) => {
    const [selectedDay, setSelectedDay] = useState(null)
    const [openTime, setOpenTime] = useState("")
    const [closeTime, setCloseTime] = useState("")
    const [daysOfWeekOptions, setDaysOfWeekOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (isOpen) {
            fetchBusinessHours()
        }
    }, [isOpen])

    useEffect(() => {
        if (isUpdate && businessHours) {
            setSelectedDay(
                daysOfWeekOptions.find((day) => day.value === businessHours.dayOfWeek)
            )
            setOpenTime(businessHours.openTime)
            setCloseTime(businessHours.closeTime)
        } else {
            setSelectedDay(null)
            setOpenTime("")
            setCloseTime("")
        }
    }, [businessHours, daysOfWeekOptions, isUpdate])

    const fetchBusinessHours = async () => {
        try {
            const { data } = await client.query({
                query: BusinessHourDayOfWeek,
                fetchPolicy: "network-only",
            })
            const options = (data && data.dataList && data.dataList.edges || []).map((daysOfWeek) => ({
                label: daysOfWeek.description,
                value: daysOfWeek.name,
            }))

            setDaysOfWeekOptions(options)
        } catch (error) {
            setError("Error fetching data: ", error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError('')

        if (!selectedDay || !openTime || !closeTime) {
            setError("Missing Required Fields")
        }

        const userInput = {
            dayOfWeek: selectedDay && selectedDay.value && String(selectedDay.value).substr(0, 2) === "A_" ? renderEnumToInt(selectedDay.value) : null,
            openTime,
            closeTime,
        }
        if (isUpdate)
            userInput["businessHourId"] = businessHours.id
        else
            userInput["branchId"] = branchId

        try {

            const { data } = await client.mutate({
                mutation: isUpdate ? UpdateBusinessHour : CreateBusinessHour,
                variables: { input: userInput },
            })

            if ((data.createBusinessHour && data.createBusinessHour.ok) || (data.updateBusinessHour && data.updateBusinessHour.ok)) {
                setLoading(false)
                setError("")
                refetch()
                toggle()

            } else {
                const errorMessages = (data.createBusinessHour && data.createBusinessHour.errors) || (data.updateBusinessHour && data.updateBusinessHour.errors)
                const errorString = errorMessages.map((err) => `${err.field}: ${err.messages.join(', ')}`).join(', ')
                setError(errorString)
                setLoading(false)
            }

            setLoading(false)
        } catch (error) {
            setError(error.message || "Error submitting business hours.")
            setLoading(false)
        }
    }


    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {isUpdate ? "Update Business Hours" : "Add New Business Hours"}
            </ModalHeader>
            <ModalBody>
                {error && <Alert color="danger">{error}</Alert>}
                {loading && <Progress animated color="info" value="100" />}
                <Form>
                    <FormGroup>
                        <Label for="daySelect">Day of the Week</Label>
                        <Select
                            id="daySelect"
                            options={daysOfWeekOptions}
                            value={selectedDay}
                            onChange={setSelectedDay}
                            placeholder="Select Day"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="openTime">Open Time</Label>
                        <Input
                            type="time"
                            id="openTime"
                            value={openTime}
                            onChange={(e) => setOpenTime(e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="closeTime">Close Time</Label>
                        <Input
                            type="time"
                            id="closeTime"
                            value={closeTime}
                            onChange={(e) => setCloseTime(e.target.value)}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit} disabled={loading}>
                    {isUpdate ? "Update" : "Create"}
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default withApollo(BusinessHoursModal)
