import React, { Component } from 'react'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo, graphql } from 'react-apollo/index'
import { Col, Row } from 'reactstrap'
import MassMessageModal from '../../Material/MassMessageModal'
import AddNewDriver from './AddNewDriver'
import WeeklyChargeModal from '../../Material/WeeklyChargeModal'
import RepAssignmentModal from "../../Material/RepAssignmentModal"
import { HasPermissionsQuery } from "../../Functions/querys"
import ConfirmationPopup from "../../Material/ConfirmationPopup"
import TablePreferencePicker from "../../Material/TablePreferencePicker"
import ActionsPopup from "./ActionsPopup"
import './DriversListContainer.css'

const assignDriverToRepMutation = gql`
mutation CreateNewAssignment ($repID:ID!, $drivers:ID!){
    createAssignment(input:{repId:$repID, drivers:[$drivers]}){
        assignment{
            id
        }
    }
}`


const UpdateDriver = gql`
    mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
    }
} `

class DriverActions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null
        }
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    unAssignDrivers = () => {
        let input = {
            repID: "none",
            drivers: this.props.selectedDrivers
        }
        this.props.client.mutate({
            mutation: assignDriverToRepMutation,
            variables: input,
        }).then((result) => {
            this.props.refetchQuery()
        })
    };
    updateDriver = (id, newvals) => {
        let input = {
            id: id,
            ...newvals
        }
        this.props.update(input).then(result => { this.props.refetchQuery() })
    }
    toggleHideDrivers = (action) => {
        let selectedDrivers = this.props.selectedDrivers
        selectedDrivers.map(item => {
            this.updateDriver(item, { hideFromCollections: action })
        })
        this.props.resetSelectedDrivers()
    }
    updateAutoCharge = () => {
        let selectedDrivers = this.props.selectedDrivers
        selectedDrivers.map(item => {
            this.updateDriver(item, { autoCharge: this.state.autoCharge })
        })
        this.props.resetSelectedDrivers()
        this.toggleModal("")
    }

    render() {
        return (
            <Row>
                <Col xs={12} className="text-right mt-3">
                    {this.props.activeTab !== "Schedules" &&
                        <>
                            <a id="toggleColumnPickerButton" className="driver-list-button" onClick={() => this.toggleModal("viewColumnPicker")}>
                                PREFERENCE | <i class="fa fa-table" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                    }
                    {this.state.openModal === "MassMessageModal" && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal")} open={this.state.openModal === "MassMessageModal"} drivers={this.props.selectedDrivers && this.props.selectedDrivers.length > 0 ? this.props.selectedDrivers : null} />}
                    {this.state.openModal === "AddNewDriver" && <AddNewDriver handleClose={() => this.toggleModal("AddNewDriver")} open={this.state.openModal === "AddNewDriver"} />}
                    {this.state.openModal === "WeeklyChargeModal" && <WeeklyChargeModal open={this.state.openModal === "WeeklyChargeModal"} handleClose={() => this.toggleModal(false)} />}
                    {this.state.openModal === "RepAssignmentModal" && <RepAssignmentModal
                        open={this.state.openModal === "RepAssignmentModal"}
                        title={"Assign " + this.props.selectedDrivers.length + " Selected Drivers to Reps"}
                        drivers={this.props.selectedDrivers}
                        handleClose={() => this.toggleModal(false)}
                        refetchDrivers={this.props.refetchQuery}
                        groups={this.props.activeTab === "Collections" ? ["Collectors"] : []}
                    />}
                    {this.state.openModal === "autoChargeConfirmation" &&
                        <ConfirmationPopup
                            open={this.state.openModal === "autoChargeConfirmation"}
                            message={"Are you sure you want to turn " + (this.state.autoCharge ? "on" : "off") + " auto charge for " + this.props.selectedDrivers.length + " selected drivers?"}
                            loading={this.state.loading}
                            handleClose={() => this.toggleModal("")}
                            action={"Yes"}
                            confirmAction={this.updateAutoCharge}
                            color="danger"
                            error={this.state.error ? this.state.error : ""}
                            success={this.state.insuranceCreated ? "Action Performed Successfully" : ""}
                        />
                    }
                    {this.state.openModal === "viewActions" && <ActionsPopup
                        handleClose={() => this.toggleModal("")}
                        openModal={this.state.openModal}
                        activeTab={this.props.activeTab}
                        selectedDrivers={this.props.selectedDrivers}
                        filterValues={this.props.filterValues}
                        hasPermissions={this.props.hasPermissions}
                        toggleHideDrivers={this.toggleHideDrivers}
                        unAssignDrivers={this.unAssignDrivers}
                        handleUpdateAutoCharge={(modalName, value) => this.setState({ openModal: modalName, autoCharge: value })}
                        toggleModal={this.toggleModal}
                    />}
                    <a id="viewActions" className="driver-list-button" onClick={() => this.toggleModal("viewActions")}>
                        ACTIONS | <i className="fa fa-cog" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.openModal === "viewColumnPicker" &&
                        <TablePreferencePicker columnConfig={this.props.columnConfig} currentUser={this.props.currentUser} open={this.state.openModal === "viewColumnPicker"} target="toggleColumnPickerButton" setColumns={this.props.setColumns} refetchPreferences={this.props.refetchPreferences} preferenceType={this.props.preferenceType} />
                    }
                </Col>
                {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <Col xs={12} className="mt-2 text-right">
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <><span className="search-filter-preview">
                        <span><i className="fa fa-times-circle" onClick={this.props.resetSelectedDrivers}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedDrivers.length == this.props.driverCountOnPage ? `All ${this.props.driverCountOnPage} on this page` : this.props.selectedDrivers.length}
                    </span>
                        {this.props.totalDriverCount > this.props.selectedDrivers.length && this.props.selectedDrivers.length == this.props.driverCountOnPage && <>&nbsp;&nbsp;<a className="driver-list-button">Select all {this.props.totalDriverCount} drivers in this list </a></>}
                    </>}
                </Col>}
            </Row>
        )
    }
}

export default compose(
    withApollo,
    graphql(UpdateDriver, {
        props: ({ mutate }) => ({
            update: (input) => mutate({ variables: { input } }),
        }),
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({
            variables: {
                userPermissions: [
                    "custom_can_unhide_collection",
                    "custom_can_hide_collection",
                    "add_driverassignment"
                ]
            }
        }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(DriverActions)
