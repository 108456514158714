import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Label, Input, FormGroup, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Badge, TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment'
import Select from "react-select"
import toTitleCase from "../../Functions/toTitleCase"
import StripeTerminal from "../../Stripe/StripeTerminal"
import CarModelGroupIcon from "../../CA/CarModelGroupIcon"
import './RunPickupModal.css'
import { CreateAgreementMutation } from "./Mutations"
import { HasPermissionsQuery } from "../../Functions/querys"
import { ReservationQuery, AllAgreementTypes, AllPaymentMethodsQuery, SubscriptionTypes, StripeCards } from "./Queries"
import gql from 'graphql-tag'
import StripeCard from "../../Stripe/StripeCard"
import PickupPaymentPlan from "./PickupPaymentPlan"
const preferredBases = ["Uber", "Lyft", "Juno", "Other"]
const paymentMethods = ["Card Payment", "Terminal Payment"]

const requestCardFromDriver = gql`mutation requestCardFromDriver($input:RequestCardFromDriverMutationInput!){
    requestCardFromDriver(input:$input){
      ok
      errors{
        field
        messages
      }
    }
}`

const VALIDATIONS_TO_SKIP_OPTIONS = ["ARGYLE"]

export function MiNavePickupQuestions({ input, updateInput }) {
    return (
        <div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Inspection Dates</Label>
                        <Input type="date" className="mb-2" name="inspectionDate1" min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} onChange={updateInput} />
                        <Input type="date" className="mb-2" name="inspectionDate2" min={input && input.inspectionDate1 ? input.inspectionDate1 : moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} onChange={updateInput} />
                        <Input type="date" className="mb-2" name="inspectionDate3" min={input && input.inspectionDate2 ? input.inspectionDate2 : moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} onChange={updateInput} />
                        <Input type="date" className="mb-2" name="inspectionDate4" min={input && input.inspectionDate3 ? input.inspectionDate3 : moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} onChange={updateInput} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Driver Accepts to notify us on any changes to thier documents</Label>
                    <div>
                        <span>YES</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="acceptToNotifyOnDocumentChanges"
                                id="acceptToNotifyOnDocumentChanges"
                                checked={!(input && input.acceptToNotifyOnDocumentChanges === true)}
                                onChange={updateInput}
                            />
                            <label className="toggle-switch-label" htmlFor="acceptToNotifyOnDocumentChanges">
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" />
                            </label>
                        </div>
                        <span>NO</span>
                    </div>
                </Col>
                <Col>
                    <Label>Driver Accepts Credit Bureau Check</Label>
                    <div>
                        <span>YES</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="acceptCreditBureauCheck"
                                id="acceptCreditBureauCheck"
                                checked={!(input && input.acceptCreditBureauCheck === true)}
                                onChange={updateInput}
                            />
                            <label className="toggle-switch-label" htmlFor="acceptCreditBureauCheck">
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" />
                            </label>
                        </div>
                        <span>NO</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Driver Authorizes Receiving Advertising</Label>
                    <div>
                        <span>YES</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="advertisingAuthorized"
                                id="advertisingAuthorized"
                                checked={!(input && input.advertisingAuthorized === true)}
                                onChange={updateInput}
                            />
                            <label className="toggle-switch-label" htmlFor="advertisingAuthorized">
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" />
                            </label>
                        </div>
                        <span>NO</span>
                    </div>
                </Col>
                <Col>
                    <Label>Driver Agrees to Review the Policy</Label>
                    <div>
                        <span>YES</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="policyReceived"
                                id="policyReceived"
                                checked={!(input && input.policyReceived === true)}
                                onChange={updateInput}
                            />
                            <label className="toggle-switch-label" htmlFor="policyReceived">
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" />
                            </label>
                        </div>
                        <span>NO</span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

class PickupModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            step: 1,
            paidAmount: 0,
            runPickupError: null,
            runPickupSuccess: false,
            loading: false,
            cardRequestLoading: false,
            cardRequest: false,
            howDriverPayingToday: "Stripe/Card",
            activeSubscriptionTypeExists: false,
            paymentSuccess: false,
            paymentTab: paymentMethods[0],
            subscriptionCreated: false,
            subscriptionTypeId: "",
            paymentMethods: null,
            willGetCdwInsurance: "",
            validationsToSkip: [],
            input: {
                preferredPaymentMethod: "Card",
                formSubmittedVia: "BOS",
                reservation: this.props.reservationId ? this.props.reservationId : "",
                ignoreMissingCarDocs: false,
                paymentPlan: null,
            }
        }
    }

    runPickup = () => {
        let reservation = this.props.reservation
        if (reservation && reservation.driver && reservation.car && !this.state.loading) {
            try {
                this.setState({ loading: true })
                let input = this.state.input
                if (this.state.willGetCdwInsurance === "Yes" && this.state.subscriptionTypeId) {
                    input["driverSubscription"] = {
                        "paymentMethod": this.state.paymentMethods,
                        "subscriptionTypeId": this.state.subscriptionTypeId
                    }
                }
                if (reservation.agreementType && reservation.agreementType.requireEndDate) {
                    if (input.returnDate) {
                        input["returnDate"] = input.returnDate
                    } else {
                        throw 'Required field missing, Return Date'
                    }
                }
                if (this.state.validationsToSkip && this.state.validationsToSkip.length > 0) {
                    input["validationsToSkip"] = this.state.validationsToSkip
                }

                this.props.client.mutate({
                    mutation: CreateAgreementMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.createAgreement.ok) {
                        this.setState({ loading: false, runPickupSuccess: true, runPickupError: null })
                    } else {
                        let error_msg = "There was a problem. Please retry."
                        if (result.data && result.data.createAgreement && result.data.createAgreement.errors) {
                            error_msg = result.data.createAgreement.errors[0].messages
                        }
                        this.setState({ loading: false, runPickupError: error_msg })
                    }
                })
            } catch (error) {
                let error_msg = "Error: " + error
                this.setState({ loading: false, runPickupError: error_msg })
            }
        }
    };

    refetchData = async () => {
        await this.props.refetchReservationQuery()
        this.props.refetchQuery()
    };

    handleClose = () => {
        let newState = {
            openModal: "",
            runPickupError: null,
            runPickupSuccess: false,
            loading: false,
            howDriverPayingToday: "Stripe/Card",
            paymentSuccess: false,
        }
        this.setState({ ...newState })
        if (this.state.runPickupSuccess && this.props.refetchQuery)
            this.refetchData()
        this.props.handleClose()
    }



    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type === "number") {
            if (isNaN(parseFloat(value))) {
                value = 0
            } else {
                value = parseFloat(value)
            }
        }
        if (e.target.type === "checkbox") {
            value = this.state.input[name] ? !this.state.input[name] : true
        }
        let input = this.state.input
        input[name] = value
        this.setState({ input: input })
    };

    paymentSuccess = (amount) => {
        this.props.refetchReservationQuery()
        this.setState({ paymentSuccess: true, paidAmount: this.state.paidAmount + amount })
    }

    handleBackEvent = () => {
        this.props.refetchReservationQuery()
        this.setState({ step: 1 })
    }

    requestCardFromDriver = () => {
        this.setState({ cardRequestLoading: true })
        let input = {
            driverId: this.props.driverId
        }
        this.props.client.mutate({
            mutation: requestCardFromDriver,
            variables: { input }
        }).then((result) => {
            if (result && result.data && result.data.requestCardFromDriver && result.data.requestCardFromDriver.ok) {
                this.setState({ cardRequestLoading: false, cardRequest: true })
            } else {
                let errorMessage = "Request New Card Failed"
                if (result.data.requestCardFromDriver.errors && result.data.requestCardFromDriver.errors[0] && result.data.requestCardFromDriver.errors[0].messages) {
                    errorMessage = result.data.requestCardFromDriver.errors[0].messages
                }
                this.setState({ error: errorMessage, cardRequestLoading: false, cardRequest: false })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.reservation !== prevProps.reservation) {
            if (this.props.reservation.agreementType && this.props.reservation.agreementType.subtypeSet && this.props.reservation.agreementType.subtypeSet.edges && this.props.reservation.agreementType.subtypeSet.edges.length >= 1) {
                this.setState({ activeSubscriptionTypeExists: true })
            }
        }
    }

    render() {
        let reservation = this.props.reservation
        let branch = reservation && reservation.branch ? reservation.branch : null
        const isMinave = branch && branch.name && branch.name.toLowerCase().includes("mi nave")
        if ((!this.state.runPickupError || this.state.runPickupError == "") && reservation && reservation.car && reservation.car.unresolvedCarIssues && reservation.car.unresolvedCarIssues.length > 0 && reservation.car.unresolvedCarIssues.filter(issue => !issue.canPickup || issue.canPickup == false).length > 0) {
            this.setState({ runPickupError: "Unable to run the pickup. Open issues found including " + (reservation.car.unresolvedCarIssues.filter(issue => !issue.canPickup || issue.canPickup == false).map(({ issueCategory }) => issueCategory.category).join(", ")) + ". Please resolve these issues." })
        }
        let requiredDepositToday = reservation && reservation.driver ? (reservation.driver.deposit ? reservation.depositAmount - reservation.driver.deposit : reservation.depositAmount) : 0
        let firstRentalCharge = reservation && reservation.reservationPrice && reservation.reservationPrice.edges && reservation.reservationPrice.edges.length > 0 ? reservation.reservationPrice.edges[0].node.price : 0
        let prepaidRentalCharge = reservation && reservation.agreementType && reservation.agreementType.chargeType === "PRE_PAY" ? parseFloat(firstRentalCharge) : 0
        let driverBalance = parseFloat((reservation && reservation.driver ? reservation.driver.branch && reservation.driver.branch.tenant && reservation.driver.branch.tenant.customId === "mnave" ? reservation.driver.bcBalance - this.state.paidAmount : reservation.driver.balance - this.state.paidAmount : 0).toFixed(2))
        let amountToPay = Math.max((reservation && reservation.driver.paymentplanSet ? reservation.driver.currentCharge : driverBalance) + requiredDepositToday + prepaidRentalCharge, 0).toFixed(2)

        let pp_rentalAmount = 0, pp_depositAmount = 0
        let rentalInputTransaction = this.state.input.paymentPlan && this.state.input.paymentPlan.inputTransactions && this.state.input.paymentPlan.inputTransactions.find(transaction => transaction.chargeTypeName === "Rental Charge")
        if (rentalInputTransaction)
            pp_rentalAmount = rentalInputTransaction.amount
        let depositInputTransaction = this.state.input.paymentPlan && this.state.input.paymentPlan.inputTransactions && this.state.input.paymentPlan.inputTransactions.find(transaction => transaction.chargeTypeName === "Deposit Charge")
        if (depositInputTransaction)
            pp_depositAmount = depositInputTransaction.amount

        let ppAmount = parseFloat(pp_rentalAmount) + parseFloat(pp_depositAmount)
        amountToPay -= ppAmount

        if (reservation && reservation.pickupType && reservation.pickupType.toLowerCase().includes("switch"))
            amountToPay = 0

        return (
            <Modal isOpen={this.props.open} className="pickup-modal" style={{ minWidth: "850px" }}>
                <ModalHeader className="title">Run Pickup</ModalHeader>
                {reservation && !this.state.loading &&
                    <ModalBody>
                        <Row>
                            {(this.state.loading || this.props.loading) && <Col xs={12}> <Progress animated color="info" value="100" /> </Col>}
                            {this.state.runPickupError && !this.state.runPickupSuccess && <Col xs={12}><Alert color="danger">{this.state.runPickupError}</Alert></Col>}
                            {!this.state.runPickupError && this.state.runPickupSuccess && <Col xs={12}><Alert color="success">{`Pick up is now compete. Please have the Driver review and sign the agreement that was sent to ${reservation.driver.email}. Thank you`} </Alert></Col>}
                        </Row>
                        <Row>
                            <Col className="align-self-center">
                                <Label>Driver Info</Label>
                                <FormGroup>
                                    <div>
                                        <span>
                                            <small>{reservation && reservation.driver.tlcLicense}&nbsp;&nbsp;
                                                <a href={"/driver/" + reservation.driver.id} target="_blank">
                                                    <i className="fa fa-external-link color-blue"></i>
                                                </a>
                                            </small>
                                        </span>
                                        <br />
                                        <span className="reservation-driver-name">{reservation && reservation.driver.name}</span>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col className="align-self-center">
                                <Label>Car Info</Label>
                                <FormGroup className="d-flex flex-row">
                                    <div className="align-self-center pr-2">
                                        <CarModelGroupIcon carGroup={reservation && reservation.car.carModel.groupType ? reservation.car.carModel.groupType.name : null} carColor={reservation && reservation.car.color} id={reservation && reservation.car.pk} />
                                        <br />
                                        <span>
                                            {reservation.car.pk}
                                        </span>
                                    </div>
                                    <div className="car-name-years align-self-center">
                                        <small>
                                            {reservation.car.year} | {reservation.car.color} {reservation.car.carModel.groupType ? "| " + reservation.car.carModel.groupType.name : ""}&nbsp;&nbsp;
                                            <a href={"/car/" + reservation.car.pk} target="_blank">
                                                <i className="fa fa-external-link color-blue"></i>
                                            </a>
                                        </small>
                                        <div className="car-name">
                                            {toTitleCase(reservation.car.carModel.make)} {toTitleCase(reservation.car.carModel.name)} <small>{reservation.car.carModel.series ? `(${reservation.car.carModel.series})` : ''}</small>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Agreement Type</Label>
                                    <br />
                                    <p>{reservation && reservation.agreementType.name}</p>
                                </FormGroup>
                            </Col>
                            <Col>
                                {reservation && reservation.agreementType && reservation.agreementType.requireEndDate &&
                                    <FormGroup>
                                        <Label>Return Date</Label>
                                        <Input type="datetime-local" value={this.state.input.returnDate} name="returnDate" min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL)} onChange={this.updateInput} />
                                    </FormGroup>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Pickup Type</Label>
                                    <br />
                                    <p>{reservation && toTitleCase(reservation.pickupType)}</p>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="preferredBase">Company Driver Will Drive For?</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={preferredBases.map(base => ({ label: base, value: base }))}
                                        placeholder="What Company will the driver drive for?"
                                        onChange={(option) => this.setState({ input: { ...this.state.input, ["preferredBase"]: option.value } })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {isMinave && <MiNavePickupQuestions input={this.state.input} updateInput={this.updateInput} />}
                        {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_skip_validations") &&
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Validations to skip</Label>
                                        <br />
                                        <Select className="bos-custom-select"
                                            classNamePrefix="bos-select" isLoading={false}
                                            options={
                                                VALIDATIONS_TO_SKIP_OPTIONS && VALIDATIONS_TO_SKIP_OPTIONS && VALIDATIONS_TO_SKIP_OPTIONS.map(
                                                    (validation) => ({ value: validation, label: validation }))
                                            }
                                            placeholder="Select validations to skip"
                                            isMulti
                                            onChange={(option) => this.setState({ validationsToSkip: option.map(item => item.value) })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        {this.state.step === 1 && this.state.activeSubscriptionTypeExists &&
                            <>
                                <hr />
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label for="willGetCdwInsurance?">Is driver signing up for CDW Deductible Insurance?</Label>
                                            <Select
                                                isDisabled={reservation.driver && reservation.driver.subSet && reservation.driver.subSet.edges && reservation.driver.subSet.edges.length > 0}
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                defaultValue={(reservation.driver && reservation.driver.subSet && reservation.driver.subSet.edges && reservation.driver.subSet.edges.length > 0) ? [{ label: "YES", value: "Yes" }] : this.state.willGetCdwInsurance === "Yes" ? [{ label: "YES", value: "Yes" }] : this.state.willGetCdwInsurance === "No" ? [{ label: "NO", value: "No" }] : null}
                                                options={[{ label: "YES", value: "Yes" }, { label: "NO", value: "No" }]}
                                                placeholder="Please Choose One"
                                                onChange={(option) => this.setState({ willGetCdwInsurance: option.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {this.state.willGetCdwInsurance === "Yes" && reservation.driver && reservation.driver.id &&
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label for="subscriptionTypeId">Which subscription is the driver signing up for?</Label>
                                                <Select
                                                    className="bos-custom-select"
                                                    classNamePrefix="bos-select"
                                                    options={this.props.allSubscriptionTypes && this.props.allSubscriptionTypes.edges && this.props.allSubscriptionTypes.edges.length > 0 && this.props.allSubscriptionTypes.edges.map(item => ({ value: item.node.id, label: item.node.name + " - Deductible: $" + item.node.deductibleAmount + " - Payment: $" + item.node.amount }))}
                                                    placeholder="Please Choose One"
                                                    onChange={(option) => this.setState({ subscriptionTypeId: option.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label for="paymentMethods">How will driver pay for this subscription?</Label>
                                                <Select
                                                    className="bos-custom-select"
                                                    classNamePrefix="bos-select"
                                                    defaultValue={[{ value: null, label: "Use Default Card" }]}
                                                    options={this.props.cards && this.props.cards.cards && this.props.cards.cards.length > 0 ? [{ value: null, label: "Use Default Card" }, ...this.props.cards.cards.map(card => ({ value: card.id, label: (card.brand + ": **" + card.last4 + ". Exp: " + card.expMonth + "/" + card.expYear) }))] : [{ value: null, label: "Use Default Card" }]}
                                                    onChange={(card) => this.setState({ paymentMethods: card.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-end">
                                            {this.state.cardRequestLoading ?
                                                <span>{this.state.cardRequestLoading ? "Loading...please wait..." : this.state.cardRequestLoading}</span> :
                                                <Button className="cdw-signup-modal-primary-btn" disabled={this.state.cardRequest} onClick={this.requestCardFromDriver}>{this.state.cardRequest ? "Link Triggred" : "Request Card"}</Button>
                                            }
                                        </Col>
                                    </Row>
                                }
                                <br />
                            </>
                        }
                        {reservation.driver && reservation.driver.subSet && reservation.driver.subSet.edges && reservation.driver.subSet.edges.length > 0 &&
                            <Row>
                                <Col xs={6}>
                                    <Label>Subscription Detail</Label>
                                    <p className="columnContent">{reservation.driver.subSet.edges[0].node.subType ? reservation.driver.subSet.edges[0].node.subType.name + " - Deductible: $" + reservation.driver.subSet.edges[0].node.subType.deductibleAmount + " - Amount: $" + reservation.driver.subSet.edges[0].node.subType.amount : "--"}</p>
                                </Col>
                                <Col xs={3}>
                                    <Label>Status</Label>
                                    <p className="columnContent">{reservation.driver.subSet.edges[0].node.statusDisplay ? reservation.driver.subSet.edges[0].node.statusDisplay : "--"}</p>
                                </Col>
                                <Col xs={3}>
                                    <Label>Created By</Label>
                                    <p className="columnContent">{reservation.driver.subSet.edges[0].node.createdBy ? reservation.driver.subSet.edges[0].node.createdBy.firstName ? reservation.driver.subSet.edges[0].node.createdBy.firstName + " " + reservation.driver.subSet.edges[0].node.createdBy.lastName : reservation.driver.subSet.edges[0].node.createdBy.username : "--"}</p>
                                </Col>
                            </Row>
                        }
                        {(this.state.step === 2 || !this.state.activeSubscriptionTypeExists) && <>
                            <hr />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Driver Balance</Label>
                                        <br />
                                        <p className={driverBalance > 0 ? "text-danger" : "text-success"}>${reservation.driver.currentCharge !== driverBalance ?
                                            <>
                                                <UncontrolledTooltip target="currentCharge">Current Charge</UncontrolledTooltip>
                                                {driverBalance}&nbsp;
                                                <i className="fa fa-long-arrow-right"></i>&nbsp;
                                                <span id="currentCharge">{reservation.driver.currentCharge}</span>
                                            </> :
                                            driverBalance}</p>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Required Deposit</Label>
                                        <br />
                                        <p>
                                            <UncontrolledTooltip target={"currentDeposit"}>Driver Current Deposit</UncontrolledTooltip>
                                            <UncontrolledTooltip target={"reservationDeposit"}>Reservation Deposit</UncontrolledTooltip>
                                            <UncontrolledTooltip target={"requiredDeposit"}>Required Deposit Today</UncontrolledTooltip>
                                            <small id="reservationDeposit">${reservation.depositAmount || 0}</small> - <small id="currentDeposit">${reservation.driver && reservation.driver.deposit ? reservation.driver.deposit : "0"}</small> = <span id="requiredDeposit" className={requiredDepositToday <= 0 ? "text-success" : "text-danger"}>${requiredDepositToday}</span>
                                        </p>
                                    </FormGroup>
                                </Col>
                                {prepaidRentalCharge > 0 && <Col>
                                    <FormGroup>
                                        <Label>Pickup Payment</Label>
                                        <br />
                                        <p>
                                            <UncontrolledTooltip target={"deposit"}>Required Deposit Today</UncontrolledTooltip>
                                            <UncontrolledTooltip target={"pickupPayment"}>Pickup Payment</UncontrolledTooltip>
                                            <UncontrolledTooltip target={"prepayRentalCharge"}>Prepay Rental Charge</UncontrolledTooltip>
                                            <small id={"deposit"}>${requiredDepositToday}</small> + <small id="prepayRentalCharge">${prepaidRentalCharge}</small> = <span id={"pickupPayment"}>${requiredDepositToday + prepaidRentalCharge}</span>
                                        </p>
                                    </FormGroup>
                                </Col>}
                                <Col>
                                    <FormGroup>
                                        <Label>Amount To Be Paid</Label>
                                        <br />
                                        <p id={"remainingBalance"} className={amountToPay > 0 ? "text-danger" : "text-success"}>
                                            ${amountToPay !== 0 ? amountToPay.toFixed(2) : 0}
                                            {(reservation && reservation.driver && reservation.driver.branch && reservation.driver.branch.tenant && reservation.driver.branch.tenant.customId !== "mnave") && !reservation.driver.hasActivePlan && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_add_plan_on_pickup") && (amountToPay > 0 || this.state.input.paymentPlan) && <>
                                                <i id="paymentPlan" className="fa fa-lg fa-credit-card float-right" style={ppAmount > 0 ? { color: "#28a745" } : { color: "#0066cc" }} aria-hidden="true" onClick={() => this.setState({ openModal: "addPP" })}></i>
                                                <UncontrolledTooltip target="paymentPlan">{this.state.input.paymentPlan ? "Edit" : "Add"} Payment Plan</UncontrolledTooltip>
                                                <PickupPaymentPlan open={this.state.openModal === "addPP"} driverId={reservation.driver.id} expectedBillingDay={reservation.expectedBillingDay} depositAmount={requiredDepositToday} rentalAmount={prepaidRentalCharge} amountToPay={amountToPay} ppInfo={this.state.input.paymentPlan} handleClose={() => this.setState({ openModal: "" })} handleSave={(data) => this.setState({ input: { ...this.state.input, paymentPlan: data } })} />
                                            </>}
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {amountToPay > 0 && reservation && reservation.driver && reservation.driver.branch && reservation.driver.branch.tenant && reservation.driver.branch.tenant.customId !== "mnave" &&
                                // Payment row
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="howDriverPayingToday">How Is The Driver Paying Today?</Label>
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                options={["Stripe/Card", "Cash"].filter(item => (reservation && reservation.branch && reservation.branch.customId === "BROOKLYN_NY") ? true : item === "Stripe/Card").map((item) => ({ label: item, value: item }))}
                                                placeholder="How is the driver"
                                                defaultValue={{ value: "Stripe/Card", label: "Stripe/Card" }}
                                                isDisabled={true}
                                                onChange={(option) => this.setState({ howDriverPayingToday: option.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {(this.state.howDriverPayingToday.includes('Stripe/Card') &&
                                        <>
                                            <Col xs={12}>
                                                <Nav tabs>
                                                    {paymentMethods.map((paymentMethod) => (
                                                        <NavItem className={this.state.paymentTab === paymentMethod ? "active" : "inactive"} style={{ "cursor": "pointer" }}>
                                                            <NavLink className={`${this.state.paymentTab === paymentMethod ? 'active' : ''}`} onClick={() => { this.setState({ paymentTab: paymentMethod }) }}>{paymentMethod} </NavLink>
                                                        </NavItem>
                                                    ))}
                                                </Nav>
                                                <br />

                                                <TabContent activeTab={this.state.paymentTab}>
                                                    <TabPane tabId={paymentMethods[0]}><Row><Col sm="12">
                                                        <StripeCard
                                                            driverId={reservation.driver ? reservation.driver.id : null}
                                                            actions={["pay", "requestCard", "cardOnFile"]}
                                                            isPickupPayment={true}
                                                            paymentSuccess={(amount) => this.paymentSuccess(amount)}
                                                            amountToPay={amountToPay}
                                                        />
                                                    </Col></Row></TabPane>
                                                    <TabPane tabId={paymentMethods[1]}><Row><Col sm="12">
                                                        <StripeTerminal
                                                            driverId={reservation.driver ? reservation.driver.id : null}
                                                            paymentSuccess={(amount) => this.paymentSuccess(amount)}
                                                            description="Payment for pickup" />
                                                    </Col></Row></TabPane>
                                                </TabContent>
                                            </Col>
                                        </>)
                                    }
                                </Row>
                            }
                        </>}
                    </ModalBody>
                }
                {this.state.loading || this.props.loading ?
                    <Progress animated color="info" value="100" /> :
                    <ModalFooter>
                        {this.state.step === 2 && this.state.activeSubscriptionTypeExists && <Col className="d-flex justify-content-start">
                            <Button className="pickup-model-secondary-btn" onClick={this.handleBackEvent}>Back</Button>
                            {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_ignore_missing_car_docs") &&
                                <span>
                                    <Label className="pt-2 pl-2" for="ignoreMissingCarDocs" >ignore missing car docs</Label>
                                    <Input
                                        type="checkbox"
                                        id="ignoreMissingCarDocs"
                                        checked={this.state.input.ignoreMissingCarDocs}
                                        onChange={() => this.setState({ input: { ...this.state.input, ["ignoreMissingCarDocs"]: !this.state.input.ignoreMissingCarDocs } })}
                                        style={{ transform: 'scale(1.2)', marginLeft: "7px", marginTop: "10px" }}
                                    />
                                </span>
                            }
                        </Col>}
                        <Col className="d-flex justify-content-end">
                            <Button className="pickup-model-secondary-btn" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                            {(this.state.step === 2 || !this.state.activeSubscriptionTypeExists) ?
                                <Button
                                    className="pickup-model-primary-btn"
                                    disabled={
                                        this.state.loading
                                        || this.state.runPickupSuccess
                                        || (this.state.howDriverPayingToday
                                            && this.state.howDriverPayingToday.includes('Stripe/Card')
                                            && amountToPay > 0
                                        )
                                        || (isMinave
                                            && (!this.state.input.acceptToNotifyOnDocumentChanges
                                                || !this.state.input.acceptToNotifyOnDocumentChanges
                                                || !this.state.input.acceptCreditBureauCheck
                                                || !this.state.input.policyReceived
                                            )
                                        )
                                    }
                                    onClick={this.runPickup}
                                >
                                    Run Pickup
                                </Button>
                                : ""
                            }
                            {this.state.activeSubscriptionTypeExists && this.state.step === 1 &&
                                <Button className="pickup-model-primary-btn"
                                    disabled={reservation.driver && reservation.driver.subSet && reservation.driver.subSet.edges && reservation.driver.subSet.edges.length > 0 ? false : (this.state.willGetCdwInsurance !== "No" && (!this.state.subscriptionTypeId))} onClick={() => this.setState({ step: 2 })}>Next</Button>}
                        </Col>
                    </ModalFooter>
                }
            </Modal>
        )
    }
}
export default compose(
    withApollo,
    graphql(AllPaymentMethodsQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allPaymentMethods } }) {
            return { loading, allPaymentMethods }
        },
    }),
    graphql(AllAgreementTypes, { props({ data: { allAgreementTypes } }) { return { allAgreementTypes } } }),
    graphql(ReservationQuery, {
        options: ({ reservationId }) => ({ variables: { id: reservationId }, fetchPolicy: "cache-and-network" }),
        props({ data: { loading, reservation, refetch, variables } }) {
            return {
                reservation, loading, variables,
                refetchReservationQuery: () => {
                    return refetch({
                        query: ReservationQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { loading, reservation: fetchMoreResult.reservation }
                        },
                    })
                },
            }
        },
    }),

    graphql(SubscriptionTypes, {
        options: ({ agreementTypeId }) => ({ fetchPolicy: 'cache-and-network', variables: { agreementTypeId: agreementTypeId } }),
        props({ data: { loading, allSubscriptionTypes } }) {
            return { loading, allSubscriptionTypes }
        },
    }),
    graphql(StripeCards, {
        options: ({ driverId }) => ({ fetchPolicy: "cache-and-network", variables: { id: driverId } }),
        props({ data: { loading, cards, variables } }) {
            return { loading, cards, variables }
        },
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_ignore_missing_car_docs", "custom_can_skip_validations", "custom_can_add_plan_on_pickup"] } }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(PickupModal)
