import React from "react"
import { Popover, PopoverHeader, PopoverBody, Button } from "reactstrap"
import moment from "moment"

const DocumentDetailsPopover = ({
    isOpen,
    target,
    doc,
    objectType,
    handleClosePopover,
}) => {
    return (
        <Popover placement="top" isOpen={isOpen} target={target}>
            <PopoverHeader>Details</PopoverHeader>
            <PopoverBody>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                        <p>Date Added:</p>
                        {objectType === "picture" ? (
                            <p>Description</p>
                        ) : (
                            <>
                                <p>Title</p>
                                <p>Expiration Date:</p>
                            </>
                        )}
                    </div>
                    <div style={{ flex: 1, textAlign: "right" }}>
                        <p>{doc.dateAdded ? moment(doc.dateAdded).format("lll") : "--"}</p>
                        {objectType === "picture" ? (
                            <p>{doc.description}</p>
                        ) : (
                            <>
                                <p>{doc.name}</p>
                                <p>{
                                    doc.expiration
                                        ? moment(doc.expiration).format(moment.HTML5_FMT.DATE)
                                        : "--"
                                }</p>
                            </>
                        )}
                    </div>
                </div>
                <Button type="button" size="sm" onClick={handleClosePopover}>
                    Close
                </Button>
            </PopoverBody>
        </Popover>
    )
}

export default DocumentDetailsPopover
