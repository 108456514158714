import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Row, Col, Label, Alert, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Table, InputGroup, InputGroupAddon, InputGroupText, Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import moment from 'moment'
import { EnumQuery } from '../../Functions/querys'
import { AllPaymentPlans } from "./Queries"
import Select from "react-select"
import { Calendar } from 'react-date-range'
import "../../Billing/Transactions/PaymentPlan.css"
import { el } from "date-fns/locale"

const WEEK_DAYS_MAPPING = {
    // python_week_day: js_week_day
    0: 1, // Monday
    1: 2, // Tuesday
    2: 3, // Wednesday
    3: 4, // Thursday
    4: 5, // Friday
    5: 6, // Saturday
    6: 0, // Sunday
}

class PickupPaymentPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: this.props.ppInfo ? this.props.ppInfo : { "intervalCount": 1, "paydownStartDate": null, "paydownNotes": "", "reason": "", "inputTransactions": [this.props.rentalAmount && { chargeTypeName: "Rental Charge", amount: this.props.rentalAmount }, { chargeTypeName: "Deposit Charge", amount: this.props.depositAmount }] },
            error: null,
            loading: false,
            validAmount: true,
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type === "number") {
            if (isNaN(parseFloat(value))) {
                value = 0
            } else {
                value = parseFloat(value)
            }
        }
        let input = this.state.input
        input[name] = value
        this.setState({ input: input, error: null })
    }
    createPaymentPlan = (e) => {
        let { input } = this.state
        console.log(input)
        this.props.handleSave({ ...input, inputTransactions: input.inputTransactions.filter(item => item && Object.hasOwn(item, 'chargeTypeName') && Object.hasOwn(item, 'amount')), paydownStartDate: moment(input["paydownStartDate"]).format(moment.HTML5_FMT.DATE) })
        this.props.handleClose()
    }

    updateInputTransaction = (chargeTypeName, amount) => {
        let input = this.state.input
        let transaction = input.inputTransactions.find(transaction => transaction.chargeTypeName === chargeTypeName)
        if (isNaN(parseFloat(amount)) || parseFloat(amount) < 0) {
            this.setState({ error: "Please enter a valid amount", validAmount: false })
            return
        }
        else {
            this.setState({ error: null, validAmount: true })
        }
        if (transaction) {
            transaction.amount = parseFloat(amount)
        } else {
            input.inputTransactions.push({ chargeTypeName, amount: parseFloat(amount) })
        }
        this.setState({ input: input, error: null })
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps.allPaymentPlans || (this.props.open && !prevProps.open)) && this.props.allPaymentPlans && this.props.allPaymentPlans.edges.length > 0 && this.props.allPaymentPlans.edges[0].node) {
            let activePlan = this.props.allPaymentPlans.edges[0].node
            let intervalCount = Math.round(parseFloat(activePlan.paydownOriginalBalance) / parseFloat(activePlan.paydownAmount))
            let reason = activePlan.reason
            let paydownNotes = activePlan.paydownNotes
            let paydownStartDate = activePlan.paydownStartDate
            this.setState({ input: { ...this.state.input, intervalCount, reason, paydownNotes, paydownStartDate } })
        }
    }

    render() {
        let { input } = this.state
        let rentalAmount = 0, depositAmount = 0
        let rentalInputTransaction = input.inputTransactions.find(transaction => transaction.chargeTypeName === "Rental Charge")
        if (rentalInputTransaction)
            rentalAmount = rentalInputTransaction.amount
        let depositInputTransaction = input.inputTransactions.find(transaction => transaction.chargeTypeName === "Deposit Charge")
        if (depositInputTransaction)
            depositAmount = depositInputTransaction.amount

        let paydownOriginalBalance = parseFloat(rentalAmount) + parseFloat(depositAmount)

        let activePlan = this.props.allPaymentPlans && this.props.allPaymentPlans.edges.length > 0 ? this.props.allPaymentPlans.edges[0].node : null

        if (activePlan) {
            paydownOriginalBalance += parseFloat(activePlan.paydownOriginalBalance)
        }

        return (
            <div>
                <Modal isOpen={this.props.open} className="payment-plan">
                    <ModalHeader toggle={this.toggle}>Create Payment Plan</ModalHeader>
                    <ModalBody>
                        {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                        {this.props.loading ? <Col xs={12}><Progress style={{ marginTop: "10px", maxHeight: "5px" }} animated color="info" value="100" /></Col> :
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownOriginalBalance">Total amount driver is paying off</Label>
                                        <Input type={"number"} min={0} name="paydownOriginalBalance" id="paydownOriginalBalance" disabled value={paydownOriginalBalance} />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownStartDate">When will the paydown start?</Label>
                                        {activePlan ? <Input type={"date"} name="paydownStartDate" id="paydownStartDate" disabled value={this.state.input.paydownStartDate} /> :
                                            <InputGroup className="mb-0 date-range-group">
                                                <InputGroupAddon addonType="prepend">
                                                    <a id="dateSelection" onClick={() => this.setState({ openModal: "dateSelection" })}><InputGroupText className="h-100"><i className="fa fa-calendar" aria-hidden="true"></i></InputGroupText></a>
                                                </InputGroupAddon>
                                                <Input disabled placeholder="Select Date" value={this.state.input.paydownStartDate ? moment(this.state.input.paydownStartDate).format("ddd, MMM D YYYY") : ""} />
                                                <Popover placement="bottom" isOpen={this.state.openModal === "dateSelection"} target="dateSelection" toggle={() => this.setState({ openModal: "" })}>
                                                    <PopoverHeader className="text-center">Select Date</PopoverHeader>
                                                    <PopoverBody>
                                                        <Calendar
                                                            id="paydownStartDate"
                                                            name="paydownStartDate"
                                                            date={this.state.input.paydownStartDate}
                                                            minDate={(() => {
                                                                let minDate = new Date()
                                                                minDate.setDate(minDate.getDate() + 1) // Add one day to the current date
                                                                return minDate
                                                            })()}
                                                            disabledDay={(date) => date.getDay() != WEEK_DAYS_MAPPING[this.props.expectedBillingDay]}
                                                            onChange={(date) => this.setState({ input: { ...this.state.input, paydownStartDate: date } })}
                                                        />
                                                    </PopoverBody>
                                                </Popover>
                                            </InputGroup>}
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownAmount">Weekly paydown amount</Label>
                                        <Input type={"number"} name="paydownAmount" id="paydownAmount" disabled value={parseFloat(paydownOriginalBalance / this.state.input.intervalCount).toFixed(2)} />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="intervalCount">Intervals to split the amount</Label>
                                        <Input type={"number"} min={0} name="intervalCount" id="intervalCount" disabled={this.props.loading || activePlan} onChange={this.updateInput} value={this.state.input.intervalCount} defaultValue={this.state.input.intervalCount} placeholder="Enter intervals count" />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="reason">Select paydown reason</Label>
                                        {activePlan ? <Input type={"text"} name="reason" id="reason" disabled value={this.state.input.reason} placeholder="Reason" /> :
                                            <Select
                                                className="bos-custom-select mt-2 mb-2"
                                                classNamePrefix="bos-select"
                                                name="reason"
                                                defaultValue={this.state.input.reason && this.props.paymentPlanReasons && { value: this.state.input.reason, label: this.props.paymentPlanReasons.states.find(reason => reason.description === this.state.input.reason).description }}
                                                options={this.props.paymentPlanReasons && this.props.paymentPlanReasons.states.map(reason => ({ value: reason.description, label: reason.description }))}
                                                placeholder="Select Reason"
                                                onChange={(option) => this.setState({ input: { ...this.state.input, reason: option.value } })}
                                            />}
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="paydownNotes">Paydown notes</Label>
                                        <Input type={"text"} name="paydownNotes" id="paydownNotes" onChange={this.updateInput} disabled={this.props.loading || activePlan} value={this.state.input.paydownNotes} defaultValue={this.state.input.paydownNotes} placeholder="Notes" />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <Table responsive>
                                        <thead style={{ position: "sticky", top: -1, backgroundColor: "white" }}>
                                            <tr style={{ color: "black" }}>
                                                <th>Charge Type</th>
                                                <th>Amount</th>
                                                <th>Plan Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.loading && <tr><td colSpan={12}><Progress style={{ maxHeight: "5px" }} animated color="info" value="100" /></td></tr>}
                                            {this.props.rentalAmount > 0 && <tr>
                                                <td className="shortText">
                                                    Rental Charge
                                                </td>
                                                <td>
                                                    ${this.props.rentalAmount}
                                                </td>
                                                <td>
                                                    <Input type={"number"} min={1} max={this.props.rentalAmount} onChange={(e) => this.updateInputTransaction("Rental Charge", e.target.value)} defaultValue={rentalAmount} />
                                                </td>
                                            </tr>}
                                            <tr>
                                                <td className="shortText">
                                                    Deposit Charge
                                                </td>
                                                <td>
                                                    ${this.props.depositAmount}
                                                </td>
                                                <td>
                                                    <Input type={"number"} min={1} max={this.props.depositAmount} onChange={(e) => this.updateInputTransaction("Deposit Charge", e.target.value)} defaultValue={depositAmount} />
                                                </td>
                                            </tr>
                                        </tbody >
                                    </Table >
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button className="payment-plan-modal-primary-btn" onClick={this.createPaymentPlan} disabled={!this.state.validAmount || this.state.error || this.state.loading || this.state.input.reason === "" || this.state.input.intervalCount <= 1 || !this.state.input.paydownStartDate || depositAmount > this.props.depositAmount || (this.props.rentalAmount > 0 && rentalAmount > this.props.rentalAmount)}>{this.state.loading ? "Loading..." : "Create Payment Plan"}</Button>&nbsp;&nbsp;
                        <Button className="payment-plan-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, { props({ data: { paymentPlanReasons } }) { return { paymentPlanReasons } } }),
    graphql(AllPaymentPlans, {
        options: ({ driverId }) => ({
            variables: {
                driverId,
                status: "1",
                first: 1,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allPaymentPlans, variables, refetch } }) {
            return {
                loading,
                allPaymentPlans,
            }
        },
    }),
)(PickupPaymentPlan)
