import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, UncontrolledTooltip } from 'reactstrap'
import UpdatePopup from './UpdatePopup'

const EditPricingModal = (props) => {
    const [price, setPrice] = useState({})
    const [openModal, setOpenModal] = useState("")

    const toggleModal = (name) => {
        setOpenModal(openModal === name ? "" : name)
    }
    useEffect(() => {
        if (props.price) {
            setPrice(props.price)
        }
    }, [props.price])

    return (
        <div>
            <Modal isOpen={props.isOpen} className="edit-pricing-modal">
                <ModalHeader >Edit Pricing</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label className={"toggle-section-heading active"}>Pricing Details</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Agreement Type</Label>
                            <p><small>{price.agreementType ? price.agreementType.name : "--"}</small></p>
                        </Col>
                        <Col>
                            <Label>Title</Label>
                            <p><small>{price.title || "--"}</small></p>
                        </Col>
                        <Col>
                            <Label>Car Model</Label>
                            <p><small>{price.carModel ? `${price.carModel.make} ${price.carModel.name}` : "For All Models"}</small></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Is Default &nbsp;&nbsp;&nbsp;&nbsp;<i id={"isDefault" + price.pk} onClick={() => toggleModal("isDefault" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>
                                &nbsp;&nbsp;{price.isDefault ? <>
                                    <i id={"priceIsDefault" + price.pk} className="ml-2 fa fa-check text-success" />
                                    <UncontrolledTooltip placement="right" target={"priceIsDefault" + price.pk}>Default Price</UncontrolledTooltip>
                                </> : <>
                                    <i id={"priceIsNotDefault" + price.pk} className="ml-2 fa fa-times text-danger" />
                                    <UncontrolledTooltip placement="right" target={"priceIsNotDefault" + price.pk}>Not-Default Price</UncontrolledTooltip>
                                </>}
                                {openModal === "isDefault" + price.pk &&
                                    <UpdatePopup
                                        target={"isDefault" + price.pk}
                                        isOpen={openModal == "isDefault" + price.pk}
                                        header="Update Template Is Default"
                                        dataId={price.id}
                                        value={price.isDefault}
                                        name="isDefault"
                                        type="checkbox"
                                        refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                        handleClose={() => toggleModal("isDefault" + price.pk)}
                                    />
                                }
                            </p>
                        </Col>
                        <Col>
                            <Label>Is Active &nbsp;&nbsp;&nbsp;&nbsp;<i id={"isActive" + price.pk} onClick={() => toggleModal("isActive" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>
                                &nbsp;&nbsp;{price.isActive ? <>
                                    <i id={"priceIsActive" + price.pk} className="ml-2 fa fa-check text-success" />
                                    <UncontrolledTooltip placement="right" target={"priceIsActive" + price.pk}>Active Price</UncontrolledTooltip>
                                </> : <>
                                    <i id={"priceIsNotActive" + price.pk} className="ml-2 fa fa-times text-danger" />
                                    <UncontrolledTooltip placement="right" target={"priceIsNotActive" + price.pk}>In-Active Price</UncontrolledTooltip>
                                </>}
                                {openModal === "isActive" + price.pk &&
                                    <UpdatePopup
                                        target={"isActive" + price.pk}
                                        isOpen={openModal == "isActive" + price.pk}
                                        header="Update Template Is Active"
                                        dataId={price.id}
                                        value={price.isActive}
                                        name="isActive"
                                        type="checkbox"
                                        refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                        handleClose={() => toggleModal("isActive" + price.pk)}
                                    />
                                }
                            </p>
                        </Col>
                        <Col>
                            <Label>Interval</Label>
                            <p><small>{price.interval} {price.intervalUnit}</small></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Customer Visibility &nbsp;&nbsp;&nbsp;&nbsp;<i id={"visibleToCustomers" + price.pk} onClick={() => toggleModal("visibleToCustomers" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>{price.visibleToCustomers ? "True" : "False"}</p>
                            {openModal === "visibleToCustomers" + price.pk &&
                                <UpdatePopup
                                    target={"visibleToCustomers" + price.pk}
                                    isOpen={openModal == "visibleToCustomers" + price.pk}
                                    header="Update Template Is visible to Customer"
                                    dataId={price.id}
                                    value={price.visibleToCustomers}
                                    name="visibleToCustomers"
                                    type="checkbox"
                                    refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                    handleClose={() => toggleModal("visibleToCustomers" + price.pk)}
                                />
                            }
                        </Col>
                        <Col>
                            <Label>Price</Label>
                            <p><small>{price.price ? "$" + price.price : "--"}</small></p>
                        </Col>
                        <Col>
                            <Label>Minimum Price &nbsp;&nbsp;&nbsp;&nbsp;<i id={"updateMinimumPrice" + price.pk} onClick={() => toggleModal("updateMinimumPrice" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>${price.minimumPrice}</p>
                            {openModal === "updateMinimumPrice" + price.pk &&
                                <UpdatePopup
                                    target={"updateMinimumPrice" + price.pk}
                                    isOpen={openModal == "updateMinimumPrice" + price.pk}
                                    header="Update Template Minimum Price"
                                    dataId={price.id}
                                    value={price.minimumPrice}
                                    name="minimumPrice"
                                    type="number"
                                    refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                    handleClose={() => toggleModal("updateMinimumPrice" + price.pk)}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Maximum Price &nbsp;&nbsp;&nbsp;&nbsp;<i id={"updatemaximumPrice" + price.pk} onClick={() => toggleModal("updatemaximumPrice" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>{price.maximumPrice ? "$" + price.maximumPrice : "--"}</p>
                            {openModal === "updatemaximumPrice" + price.pk &&
                                <UpdatePopup
                                    target={"updatemaximumPrice" + price.pk}
                                    isOpen={openModal == "updatemaximumPrice" + price.pk}
                                    header="Update Template Maximum Price"
                                    dataId={price.id}
                                    value={price.maximumPrice}
                                    name="maximumPrice"
                                    type="number"
                                    refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                    handleClose={() => toggleModal("updatemaximumPrice" + price.pk)}
                                />
                            }
                        </Col>
                        <Col>
                            <Label>Minimum Year &nbsp;&nbsp;&nbsp;&nbsp;<i id={"updatecarYearMin" + price.pk} onClick={() => toggleModal("updatecarYearMin" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>{price.carYearMin}</p>
                            {openModal === "updatecarYearMin" + price.pk &&
                                <UpdatePopup
                                    target={"updatecarYearMin" + price.pk}
                                    isOpen={openModal == "updatecarYearMin" + price.pk}
                                    header="Update Template Car Year Minimum"
                                    dataId={price.id}
                                    value={price.carYearMin}
                                    name="carYearMin"
                                    type="number"
                                    refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                    handleClose={() => toggleModal("updatecarYearMin" + price.pk)}
                                />
                            }
                        </Col>
                        <Col>
                            <Label>Maximum Year &nbsp;&nbsp;&nbsp;&nbsp;<i id={"updatecarYearMax" + price.pk} onClick={() => toggleModal("updatecarYearMax" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>{price.carYearMax}</p>
                            {
                                openModal === "updatecarYearMax" + price.pk &&
                                <UpdatePopup
                                    target={"updatecarYearMax" + price.pk}
                                    isOpen={openModal == "updatecarYearMax" + price.pk}
                                    header="Update Template Car Year Maximum"
                                    dataId={price.id}
                                    value={price.carYearMax}
                                    name="carYearMax"
                                    type="number"
                                    refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                    handleClose={() => toggleModal("updatecarYearMax" + price.pk)}
                                />
                            }
                        </Col >
                    </Row >
                    <Row>
                        <Col>
                            <Label>Minimum Mileage &nbsp;&nbsp;&nbsp;&nbsp;<i id={"updatecarMileageMin" + price.pk} onClick={() => toggleModal("updatecarMileageMin" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>
                                {price.agreementType && price.agreementType.branch && price.agreementType.branch.defaultMileageUnit && price.agreementType.branch.defaultMileageUnit === 'KM'
                                    ? `${parseFloat(price.carMileageMinInKm).toFixed(2)} KM`
                                    : `${parseFloat(price.carMileageMin).toFixed(2)} MI`
                                }
                            </p>
                            {
                                openModal === "updatecarMileageMin" + price.pk &&
                                <UpdatePopup
                                    target={"updatecarMileageMin" + price.pk}
                                    isOpen={openModal == "updatecarMileageMin" + price.pk}
                                    header="Update Template Car Mileage Minimum"
                                    dataId={price.id}
                                    value={price.carMileageMin && price.agreementType && price.agreementType.branch && price.agreementType.branch.defaultMileageUnit && price.agreementType.branch.defaultMileageUnit === 'KM'
                                        ? parseFloat(price.carMileageMinInKm).toFixed(2)
                                        : parseFloat(price.carMileageMin).toFixed(2)
                                    }
                                    name="carMileageMin"
                                    type="number"
                                    mileageUnit={price.agreementType && price.agreementType.branch && price.agreementType.branch.defaultMileageUnit}
                                    refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                    handleClose={() => toggleModal("updatecarMileageMin" + price.pk)}
                                />
                            }
                        </Col >
                        <Col>
                            <Label>Maximum Mileage &nbsp;&nbsp;&nbsp;&nbsp;<i id={"updatecarMileageMax" + price.pk} onClick={() => toggleModal("updatecarMileageMax" + price.pk)} className={"fa fa-pencil"} /></Label>
                            <p>
                                {price.agreementType && price.agreementType.branch && price.agreementType.branch.defaultMileageUnit && price.agreementType.branch.defaultMileageUnit === 'KM'
                                    ? `${parseFloat(price.carMileageMaxInKm).toFixed(2)} KM`
                                    : `${parseFloat(price.carMileageMax).toFixed(2)} MI`
                                }
                            </p>

                            {
                                openModal === "updatecarMileageMax" + price.pk &&
                                <UpdatePopup
                                    target={"updatecarMileageMax" + price.pk}
                                    isOpen={openModal == "updatecarMileageMax" + price.pk}
                                    header="Update Template Car Mileage Maximum"
                                    dataId={price.id}
                                    value={price.carMileageMax && price.agreementType && price.agreementType.branch && price.agreementType.branch.defaultMileageUnit && price.agreementType.branch.defaultMileageUnit === 'KM'
                                        ? parseFloat(price.carMileageMaxInKm).toFixed(2)
                                        : parseFloat(price.carMileageMax).toFixed(2)}
                                    name="carMileageMax"
                                    type="number"
                                    mileageUnit={price.agreementType && price.agreementType.branch && price.agreementType.branch.defaultMileageUnit}
                                    refetchAllPricingTemplates={() => props.refetchAllPricingTemplates()}
                                    handleClose={() => toggleModal("updatecarMileageMax" + price.pk)}
                                />
                            }
                        </Col >
                        <Col></Col>
                    </Row >
                </ModalBody >
                <ModalFooter>
                    <Button className="edit-pricing-modal-secondary-btn" onClick={props.handleClose}>Cancel</Button>
                </ModalFooter>
            </Modal >
        </div >
    )
}

export default EditPricingModal
