//  Write and export functions to update address in a modal

import React, { useState } from 'react'
import { CREATE_ADDRESS_MUTATION, UPDATE_ADDRESS_MUTATION } from "../Mutations"
import { Row, Col, ModalBody, ModalHeader, Alert, FormGroup, Label, Input, Progress, ModalFooter, Button, Modal, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import Select from "react-select"
import "./DriverDetailNew.css"
import SearchPlaces from "../../../../Material/SearchPlaces"
import { COUNTRIES } from "../../../../Constants"
import { getCitiesByState, getCityOptions, getStateOptions, getStatesByCountry } from "../../../../utils"

export function CreateAddress({ client, driverId, driverName, open, handleClose, refetchDriverQuery }) {
    const [input, setInput] = useState({
        title: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: COUNTRIES && COUNTRIES[0].value,
        driverId: driverId
    })
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const renderCityDropdown = (selectedState) => {
        const cityOptions = getCityOptions(getCitiesByState(selectedState, input.country))

        return <Select
            className="bos-custom-select" classNamePrefix="bos-select"
            isDisabled={!input.state}
            value={input.city ? cityOptions.find((city) => city.value === input.city) : null}
            options={cityOptions || []}
            placeholder="Select City"
            onChange={(selectedCity) => setInput({ ...input, city: selectedCity.value })}
        />
    }

    const renderStateDropdown = (countryCode) => {
        const stateOptions = getStateOptions(getStatesByCountry(countryCode))

        return (
            <Select
                className="bos-custom-select"
                classNamePrefix="bos-select"
                value={input.state ? stateOptions.find((state) => state.value === input.state) : null}
                options={stateOptions || []}
                placeholder="Select State"
                onChange={(selectedState) => setInput({ ...input, state: selectedState.value })}
            />
        )
    }



    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        setError('')
        let errors = []
        const userInput = input
        if (!userInput.addressLine1 || !userInput.city || !userInput.state || !userInput.postalCode || !userInput.country || !userInput.title) {
            errors.push('Please fill out all fields')
        }
        if (userInput.country === 'US' && userInput.postalCode.length !== 5) {
            errors.push('Please enter a valid 5 digit US postal code')
        }
        if (userInput.country === 'US' && userInput.state.length !== 2) {
            errors.push('Please enter a valid 2 letter US state code')
        }
        if (errors.length > 0) {
            setError(errors.join(', '))
            setLoading(false)
            return
        }

        try {
            const { data } = await client.mutate({
                mutation: CREATE_ADDRESS_MUTATION,
                variables: { input: userInput }
            })
            if (data && data.createAddress && data.createAddress.ok) {
                setSuccess(true)
                setLoading(false)
                setTimeout(() => {
                    setSuccess(false)
                    refetchDriverQuery()
                    handleClose()
                }, 1000)
            } else if (data && data.createAddress && data.createAddress.errors && data.createAddress.errors.length > 0) {
                setError(data.createAddress.errors.map(e => e.messages.join(', ')).join(', '))
                setLoading(false)
            }

        } catch (error) {
            setError(error.message ? error.message : "Error creating address")
            setLoading(false)
        }
    }
    if (!driverId || !client) return null
    return (
        <Modal isOpen={open} className="driverAddress">
            <ModalHeader>New Address for {driverName || driverId} </ModalHeader>
            <ModalBody>
                <Row>
                    {error && <Col xs={12}><Alert color="danger">{error}</Alert></Col>}
                    {success && <Col xs={12}><Alert color="success">Created successfully!</Alert></Col>}
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="title"><small>Title * </small></Label>
                            <Input type="text" name="title" placeholder="Enter Address Title" onChange={(e) => setInput({ ...input, title: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="addressLine1"><small>Address Line 1 *</small></Label>
                            <SearchPlaces onSaveAction={(e) => setInput({ ...input, addressLine1: e.value })} initialValue={null} searchType={"address"} />
                        </FormGroup>
                    </Col>

                    <Col xs={12}>
                        <FormGroup>
                            <Label for="addressLine2"><small>Address Line 2</small></Label>
                            <SearchPlaces onSaveAction={(e) => setInput({ ...input, addressLine2: e.value })} initialValue={null} searchType={"address"} />
                        </FormGroup>
                    </Col>

                    <Col xs={6}>
                        <FormGroup>
                            <Label for="country"><small>Country *</small></Label>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                placeholder="Select Country"
                                options={COUNTRIES ? COUNTRIES : null}
                                value={input.country ? COUNTRIES.find((country) => country.value === input.country) : null}
                                onChange={(selectedCountry) => setInput({ ...input, country: selectedCountry.value, state: '', city: '' })}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label for="state"><small>State *</small></Label>
                            {renderStateDropdown(input.country)}
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label for="city"><small>City *</small></Label>
                            {renderCityDropdown(input.state)}
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label for="postalCode"><small>Postal Code *</small></Label>
                            <Input type="number" name="postalCode" placeholder="Enter Postal Code" onChange={(e) => setInput({ ...input, postalCode: e.target.value })} />
                        </FormGroup>
                    </Col>

                    {loading && <Col xs={12}><Progress animated color="info" value="100" /></Col>}
                </Row>
            </ModalBody>
            <ModalFooter>
                {!loading &&
                    <Row>
                        <Col xs={12}>
                            <Button outline color="secondary" onClick={handleClose}>Close</Button>&nbsp;&nbsp;
                            <Button className="submit-btn" color="primary" onClick={handleSubmit}
                                disabled={!input.addressLine1 || !input.city || !input.state || !input.postalCode || !input.country || !input.title || loading || success}>Create Address</Button>
                        </Col>
                    </Row>
                }
            </ModalFooter>
        </Modal>
    )
}




//  Update
export function UpdateAddress({ client, addressId, addressLine1, addressLine2, city, state, postalCode, country, driverName, open, handleClose }) {
    const [input, setInput] = useState({})
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const updateInput = ({ key, value }) => {
        setInput({ ...input, [key]: value })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        setError('')
        let errors = []

        if (input.country && input.postalCode && input.country === 'US' && input.postalCode.length !== 5) {
            errors.push('Please enter a valid 5 digit US postal code')
        }
        if (input.country && input.state && input.country === 'US' && input.postalCode.state !== 2) {
            errors.push('Please enter a valid 2 letter US state code')
        }
        if (errors.length > 0) {
            setError(errors.join(', '))
            setLoading(false)
            return
        }
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_ADDRESS_MUTATION,
                variables: { input }
            })
            if (data && data.updateAddress && data.updateAddress.ok) {
                setSuccess(true)
                setLoading(false)
                setTimeout(() => {
                    setSuccess(false)
                    handleClose()
                }, 1000)
            } else if (data && data.updateAddress && data.updateAddress.errors && data.updateAddress.errors.length > 0) {
                setError(data.updateAddress.errors.map(e => e.messages.join(', ')).join(', '))
                setLoading(false)
            }

        } catch (error) {
            setError(error.message ? error.message : "Error creating address")
            setLoading(false)
        }
    }
    if (!addressId || !client) return null
    return (
        <div>
            <Modal isOpen={open}>
                <ModalHeader>Update Address for {driverName || addressId} </ModalHeader>
                <ModalBody>
                    <Row>
                        {error && <Col xs={12}><Alert color="danger">{error}</Alert></Col>}
                        {success && <Col xs={12}><Alert color="success">Updated successfully!</Alert></Col>}

                        <Col xs={8}>
                            <FormGroup>
                                <Label for="document">Address Line 1</Label>
                                <Input type="text" name="addressLine1" defaultValue={addressLine1} onChange={(e) => updateInput({ key: e.target.name, value: e.target.value })} />
                            </FormGroup>
                        </Col>

                        <Col xs={4}>
                            <FormGroup>
                                <Label for="document">Address Line 2</Label>
                                <Input type="text" name="addressLine2" defaultValue={addressLine2} onChange={(e) => updateInput({ key: e.target.name, value: e.target.value })} />
                            </FormGroup>
                        </Col>

                        <Col xs={4}>
                            <FormGroup>
                                <Label for="document">City </Label>
                                <Input type="text" name="city" defaultValue={city} onChange={(e) => updateInput({ key: e.target.name, value: e.target.value })} />
                            </FormGroup>
                        </Col>

                        <Col xs={4}>
                            <FormGroup>
                                <Label for="document">State </Label>
                                <Input type="text" name="state" defaultValue={state} onChange={(e) => updateInput({ key: e.target.name, value: e.target.value })} />
                            </FormGroup>
                        </Col>

                        <Col xs={4}>
                            <FormGroup>
                                <Label for="document">Postal Code </Label>
                                <Input type="text" name="postalCode" defaultValue={postalCode} onChange={(e) => updateInput({ key: e.target.name, value: e.target.value })} />
                            </FormGroup>
                        </Col>

                        <Col xs={4}>
                            <FormGroup>
                                <Label for="document">Country </Label>
                                <UncontrolledDropdown className="w-full">
                                    <DropdownToggle className="w-full" caret>{input.country || country} </DropdownToggle>
                                    <DropdownMenu className="w-full">
                                        {COUNTRIES && COUNTRIES.map((item, i) => (
                                            <DropdownItem className="w-full" key={i} onChange={(e) => updateInput({ key: item.label, value: item.value })}>{item.label}</DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </FormGroup>
                        </Col>
                        {loading && <Col xs={12}><Progress animated color="info" value="100" /></Col>}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!loading &&
                        <Row>
                            <Col xs={12}>
                                <Button outline color="secondary" onClick={handleClose}>Close</Button>&nbsp;&nbsp;
                                <Button color="secondary" onClick={handleSubmit}
                                    disabled={loading || success}>Update Address</Button>
                            </Col>
                        </Row>
                    }
                </ModalFooter>
            </Modal>
        </div>
    )
}
