import React, { useState, useEffect } from "react"
import Select from "react-select"
import { GENDER_LIST, COUNTRIES, PLATFORM_LIST } from "../../Constants"
import { getStatesByCountry, getStateOptions, getCitiesByState, getCityOptions } from "../../utils"

function DocumentDataSelect({ field, handleDriverDataChange, countryCode, stateCode }) {
    const [options, setOptions] = useState([])
    const [optionName, setOptionName] = useState("")
    const isDisabled = field.fieldCode.startsWith("COUNTRY") && Boolean(field.value)

    useEffect(() => {
        if (field.fieldCode.startsWith("COUNTRY")) {
            setOptions(COUNTRIES)
            setOptionName("Country")
        } else if (field.fieldCode.startsWith("STATE")) {
            const states = getStatesByCountry(countryCode)
            const stateOptions = getStateOptions(states)
            setOptions(stateOptions)
            setOptionName("State")
        } else if (field.fieldCode.startsWith("CITY")) {
            const cities = getCitiesByState(stateCode, countryCode)
            const cityOptions = getCityOptions(cities)
            setOptions(cityOptions)
            setOptionName("City")
        } else if (field.fieldCode.startsWith("GENDER")) {
            setOptions(GENDER_LIST)
            setOptionName("Gender")
        } else if (field.fieldCode.startsWith("PLATFORM")) {
            setOptions(PLATFORM_LIST)
            setOptionName("Platform")
        }
    }, [field.fieldCode, countryCode, stateCode])


    return (
        <Select
            className="bos-custom-select"
            classNamePrefix="bos-select"
            placeholder={`Select ${optionName}`}
            options={options}
            value={options.find((option) => option.value === field.value)}
            name={field.fieldCode}
            isDisabled={isDisabled}
            onChange={(selectedOption) => {
                handleDriverDataChange({
                    name: field.fieldCode,
                    value: selectedOption ? selectedOption.value : "",
                })
            }}
        />
    )
}

export default DocumentDataSelect
