import React from 'react'
import US_STATES from "./Material/States.json"
import US_CITIES from "./Material/Cities.json"
import MX_CITIES from "./Material/MexicoCities.json"
import MX_STATES from "./Material/MexicoStates.json"


const TIMEZONES = [
    { value: "America/New_York", label: "America/New_York" },
    { value: "America/Phoenix", label: "America/Phoenix" },
    { value: "America/Houston", label: "America/Houston" },
    { value: "America/Chicago", label: "America/Chicago" },
    { value: "America/Salt Lake City", label: "America/Salt Lake City" },
    { value: "America/Anchorage", label: "America/Anchorage" },
    { value: "America/Adak", label: "America/Adak" },
    { value: "America/Los_Angeles", label: "America/Los_Angeles" },
    { value: "Mexico/Cancún", label: "Mexico/Cancún" },
    { value: "Mexico/Mexico City", label: "Mexico/Mexico City" },
    { value: "Mexico/Hermosillo", label: "Mexico/Hermosillo" }

]

const BILLING_DAYS = [
    { value: "", label: "" },
    { value: 0, label: "Monday" },
    { value: 1, label: "Tuesday" },
    { value: 2, label: "Wednesday" },
    { value: 3, label: "Thursday" },
    { value: 4, label: "Friday" },
    { value: 5, label: "Saturday" },
    { value: 6, label: "Sunday" }
]

const COLLECTION_DAYS = [
    { name: "Sunday", jsValue: 0, pyValue: 6 },
    { name: "Monday", jsValue: 1, pyValue: 0 },
    { name: "Tuesday", jsValue: 2, pyValue: 1 },
    { name: "Wednesday", jsValue: 3, pyValue: 2 },
    { name: "Thursday", jsValue: 4, pyValue: 3 },
    { name: "Friday", jsValue: 5, pyValue: 4 },
    { name: "Saturday", jsValue: 6, pyValue: 5 }
]

const GENDER_LIST = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" }
]

const PAYMENT_SCHEDULE_STATUS_COLOR_MAP = { 'PENDING': 'warning', 'DECLINED': 'danger', 'CANCELED': 'danger', 'SUCCEEDED': 'success', 'UNPROCESSABLE': 'secondary', 'SCHEDULED': 'secondary' }

const COUNTRIES = [
    { label: "United States", value: "US" },
    { label: "Mexico", value: "MX" }
]

const PLATFORM_LIST = [
    { label: "Uber", value: "UBER" },
    { label: "DiDi", value: "DIDI" },
    { label: "Lyft", value: "LYFT" }
]

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const PHONE_REGEX = /^\+1[1-9]\d{2}[1-9]\d{2}\d{4}$/

export {
    TIMEZONES, BILLING_DAYS, GENDER_LIST, PAYMENT_SCHEDULE_STATUS_COLOR_MAP, COUNTRIES, US_STATES, US_CITIES, MX_STATES, MX_CITIES,
    EMAIL_REGEX, PHONE_REGEX, COLLECTION_DAYS, PLATFORM_LIST
}