import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Alert, Button, Row, Col, Progress, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText } from 'reactstrap'
import Select from 'react-select'
import gql from 'graphql-tag'
import { PHONE_REGEX, EMAIL_REGEX } from "../Constants"
const TITLES = [
    { value: "Main", label: "MAIN" },
    { value: "Uber", label: "UBER" },
    { value: "Other TNC Provider", label: "OTHER TNC PROVIDER" },
    { value: "Invalid", label: "INVALID" }
]

const CustomerHandle = gql`query CustomerHandle($id: ID!, $includeDeleted: Boolean){
    customerHandle(id: $id, includeDeleted: $includeDeleted){
        id
        type
        title
        handle
        isPrimary
        isArchived
    }
}`

const CreateCustomerHandle = gql`mutation CreateCustomerHandle($input: CreateCustomerHandleMutationInput!){
    createCustomerHandle(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateCustomerHandle = gql`mutation UpdateCustomerHandle($input: UpdateCustomerHandleMutationInput!){
    updateCustomerHandle(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

class CustomerHandleModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            handle: "",
            customerId: this.props.customerId,
            isPrimary: false,
            isArchived: false,
            title: "",
            openModal: "",
            success: false,
            loading: false,
            error: null,
            emailValidationError: null,
            phoneValidationError: null,
        }
    }

    handleSubmit = () => {
        const { handle, customerId, isArchived, isPrimary, title } = this.state
        if (this.props.isUpdate || (handle && customerId && title)) {
            try {
                this.setState({ loading: true })
                const input = {
                    title: title || ''
                }
                if (this.props.isUpdate) {
                    input["id"] = this.props.customerHandle && this.props.customerHandle.id ? this.props.customerHandle.id : ""
                    if (isPrimary !== this.props.customerHandle.isPrimary)
                        input["isPrimary"] = isPrimary
                    if (isArchived !== this.props.customerHandle.isArchived)
                        input["isArchived"] = isArchived
                }
                if (!this.props.isUpdate) {
                    input["type"] = this.props.handleType === "email" ? "Email" : "Phone"
                    input["handle"] = handle || ''
                    input["customer"] = customerId || ''
                    input["isPrimary"] = isPrimary
                }
                this.props.client
                    .mutate({
                        mutation: this.props.isUpdate ? UpdateCustomerHandle : CreateCustomerHandle,
                        variables: { input },
                    })
                    .then((result) => {
                        if (result.data && result.data[this.props.isUpdate ? 'updateCustomerHandle' : 'createCustomerHandle'] && result.data[this.props.isUpdate ? 'updateCustomerHandle' : 'createCustomerHandle'].ok) {
                            this.setState({ loading: false, error: null, success: false })
                            if (this.props.handleClose)
                                this.props.handleClose()
                            if (this.props.refetchQuery)
                                this.props.refetchQuery()
                        } else {
                            let errorMessage = 'An error occurred, could not complete request.'
                            if (result.data && result.data[this.props.isUpdate ? 'updateCustomerHandle' : 'createCustomerHandle'] && result.data[this.props.isUpdate ? 'updateCustomerHandle' : 'createCustomerHandle'].errors && result.data[this.props.isUpdate ? 'updateCustomerHandle' : 'createCustomerHandle'].errors[0] && result.data[this.props.isUpdate ? 'updateCustomerHandle' : 'createCustomerHandle'].errors[0].messages[0]) {
                                errorMessage = result.data[this.props.isUpdate ? 'updateCustomerHandle' : 'createCustomerHandle'].errors[0].messages[0]
                            } else if (!this.props.isUpdate && result.data && result.data.createCustomerHandle && result.data.createCustomerHandle.errors && result.data.createCustomerHandle.errors[0] && result.data.createCustomerHandle.errors[0].messages[0]) {
                                errorMessage = result.data.createCustomerHandle.errors[0].messages[0]
                            }
                            this.setState({ loading: false, error: errorMessage, success: false })
                        }
                    })
                    .catch((error) => {
                        let errorMessage = 'An error has occurred ' + error
                        this.setState({ error: errorMessage, loading: false, success: false })
                    })
            } catch (error) {
                let errorMessage = 'An error has occurred ' + error
                this.setState({ error: errorMessage, loading: false, success: false })
            }
        } else {
            this.setState({ error: 'Error: Required fields are missing', success: false })
        }
    };

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "handle") {
            if (this.props.handleType === "email") {
                if (EMAIL_REGEX.test(value))
                    this.setState({ emailValidationError: null, error: null })
                else
                    this.setState({ emailValidationError: "Please enter a valid email address.", error: null })
            }
            if (this.props.handleType === "phone") {
                if (PHONE_REGEX.test(value))
                    this.setState({ phoneValidationError: null, error: null })
                else
                    this.setState({ phoneValidationError: "Please enter a valid phone number", error: null })
            }
        }
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value, error: "" })
    };

    componentDidUpdate(prevProps) {
        if (this.props.customerHandle !== prevProps.customerHandle && this.props.customerHandle && this.props.customerHandle.id) {
            this.setState({
                handle: this.props.customerHandle && this.props.customerHandle.handle ? this.props.customerHandle.handle : "",
                title: this.props.customerHandle && this.props.customerHandle.title ? this.props.customerHandle.title : "",
                isPrimary: this.props.customerHandle && this.props.customerHandle.isPrimary,
                isArchived: this.props.customerHandle && this.props.customerHandle.isArchived,
            })
        }
    }



    render() {
        return (
            <Modal className={"customer-handle-modal"} isOpen={this.props.open}>
                <ModalHeader>{this.props.isUpdate ? "UPDATE" : "CREATE"} CUSTOMER HANDLE</ModalHeader>
                <ModalBody>
                    {this.props.loading || this.state.loading ? <Progress animated color="info" value={100} /> :
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                            <Col xs={6}>
                                <FormGroup>
                                    <Label><b>TITLE *</b></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={TITLES.map(item => ({ value: item.value, label: item.label }))}
                                        defaultValue={this.props.isUpdate ? TITLES.filter(item => item.value === (this.props.customerHandle && this.props.customerHandle.title)).map(item => ({ value: item.value, label: item.label })) : ""}
                                        placeholder="CHOOSE TITLE"
                                        onChange={(title) => this.setState({ title: title.value })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label><b>{this.props.handleType === "email" ? "EMAIL" : "PHONE"} *</b></Label><br />
                                    <Input
                                        type="text"
                                        onChange={this.updateInput}
                                        name="handle"
                                        placeholder={this.props.handleType === "email" ? "Email" : "Phone"}
                                        defaultValue={this.props.isUpdate ? (this.props.customerHandle && this.props.customerHandle.handle) : ""}
                                        maxLength={this.props.handleType === "phone" ? 13 : 100}
                                        disabled={this.props.isUpdate}
                                    />
                                    <FormText><span style={{ color: "red" }}>{this.state.emailValidationError ? this.state.emailValidationError : this.state.phoneValidationError}</span></FormText>
                                    {!this.props.isUpdate && <FormText><span style={{ color: "green" }}>{this.props.handleType === "phone" ? this.props.branch && this.props.branch.customId && this.props.branch.customId.toLowerCase().includes("mx") ? "Phone number must start with +52. For example +528717548425" : "Phone number must start with +1. For example +19171230000" : "Make sure to add valid email. For example abc@gmail.com"}</span></FormText>}
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label><b>IS PRIMARY</b></Label><br />
                                    <div>
                                        <span>Yes</span>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                id="isPrimary"
                                                name={"isPrimary"}
                                                disabled={this.props.isUpdate && this.props.customerHandle && this.props.customerHandle.isPrimary}
                                                checked={!this.state.isPrimary}
                                                onChange={() => this.setState({ isPrimary: !this.state.isPrimary })}
                                            />
                                            <label style={{ opacity: this.props.isUpdate && this.props.customerHandle && this.props.customerHandle.isPrimary ? "0.5" : "1" }} className="toggle-switch-label" htmlFor={"isPrimary"}>
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                        <span>No</span>
                                    </div>
                                </FormGroup>
                            </Col>
                            {this.props.isUpdate && <Col xs={6}>
                                <FormGroup>
                                    <Label><b>IS ARCHIVED</b></Label><br />
                                    <div>
                                        <span>Yes</span>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                id={"isArchived"}
                                                name={"isArchived"}
                                                checked={!this.state.isArchived}
                                                onChange={() => this.setState({ isArchived: !this.state.isArchived })}
                                            />
                                            <label className="toggle-switch-label" htmlFor={"isArchived"}>
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                        <span>No</span>
                                    </div>
                                </FormGroup>
                            </Col>}
                        </Row>}
                </ModalBody>
                <ModalFooter>
                    <Button className="customer-handle-modal-secondary-btn" onClick={this.props.handleClose}>CLOSE</Button>&nbsp;&nbsp;
                    <Button className="customer-handle-modal-primary-btn" disabled={this.state.loading || this.state.emailValidationError || this.state.phoneValidationError} onClick={() => this.handleSubmit()}>{this.props.isUpdate ? "UPDATE HANDLE" : "CREATE HANDLE"}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(CustomerHandle, {
        options: ({ customerHandleId }) => ({ variables: { id: customerHandleId, includeDeleted: true }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { customerHandle, loading, refetch, variables } }) {
            return {
                customerHandle, loading, variables,
                refetchDriverQuery: () => {
                    return refetch({
                        query: CustomerHandle,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { customerHandle: fetchMoreResult.customerHandle }
                        },
                    })
                },
            }
        }
    }),
)(CustomerHandleModal)
