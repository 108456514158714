import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, withApollo, graphql } from 'react-apollo'
import './RiskTab.css'
import RiskInsuranceDetails from "./RiskInsuranceDetails"
import RiskSubscriptionDetails from "./RiskSubscriptionDetails"
import RiskIntegrationDetails from "./RiskIntegrationDetails"

const DriverQuery = gql`query Driver($id: ID!){
    driver(id: $id){
        id
        canProcessInsurance
        argyleAccountId
        palencaUserId
        currentAgreement{
            id
            type {
                id
                subtypeSet: subscriptiontypeSet {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
        }
        branch{
            id
        }
    }
}`

function RiskTab(props) {
    const { driver, loading, currentUser } = props
    if (loading || !driver || !currentUser) return <div></div>
    return (
        <div className="tab-container row" style={{ justifyContent: "space-between" }}>
            <div className="col-7 info-container">
                <RiskInsuranceDetails currentUser={currentUser} driver={driver} />
            </div>
            <div className="col-4 info-container">
                <RiskSubscriptionDetails currentUser={currentUser} driver={driver} />
            </div>
            <div className="col-4" style={{ padding: "0" }}>
                <RiskIntegrationDetails currentUser={currentUser} driver={driver} palencaUserId={driver.palencaUserId} argyleUserId={driver.argyleAccountId} />
            </div>
        </div>

    )
}

export default compose(
    withApollo,
    graphql(DriverQuery, {
        options: ({ driverId }) => ({ variables: { id: driverId }, fetchPolicy: 'cache-and-network' }),
        props({ data: { driver, loading, variables } }) {
            return {
                driver, loading, variables
            }
        }
    }),
)(RiskTab)
