import React from 'react'

const DocumentPreview = ({ files, getFilePreview, handleFileDelete }) => {
    const isImage = (file) => file && file.type.includes("image")
    const isPdf = (file) => file && file.type.includes("pdf")

    return (
        <ul className="file-list">
            {files && files.length > 0 && files.map((file, index) => (
                <li key={index} className="file-item">
                    {isImage(file) || isPdf(file) ? (
                        isImage(file) ? (
                            <img
                                src={getFilePreview(file)}
                                alt={file.name}
                                className="file-preview"
                            />
                        ) : (
                            <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
                                <embed
                                    scrolling="yes"
                                    width="100%"
                                    height="100%"
                                    src={getFilePreview(file)}
                                    type="application/pdf"
                                />
                            </div>
                        )
                    ) : (
                        <span>
                            <i className="fa fa-file-pdf-o" aria-hidden="true" />&nbsp;&nbsp;
                        </span>
                    )}
                    <p className="file-name-delete">
                        <span className="file-name">{file.name}</span>
                        <span
                            className="delete-icon"
                            onClick={() => handleFileDelete(index)}
                        >
                            <i className="fa fa-trash" aria-hidden="true" /> Remove
                        </span>
                    </p>
                </li>
            ))}
        </ul>
    )
}

export default DocumentPreview