import React, { Component } from "react"
import { Popover, PopoverBody, PopoverHeader, Row, Col, Input } from "reactstrap"
import { compose } from 'react-apollo'
import Select from "react-select"
import { withApollo } from 'react-apollo/index'
import MultiRangeSlider from "./MultiRangeSlider/MultiRangeSlider"
import "./ListingFilter.css"

class ListingFilters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: this.props.filterValues,
            placement: this.props.placement ? this.props.placement : "left"
        }
    }

    isFilterEnabled = (filterName) => {
        return Object.keys(this.state.filterValues).includes(filterName)
    };

    disableFilter = (filterName) => {
        let filterValues = this.state.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            this.setState({ filterValues: filterValues ? filterValues : {} })
        }
    }

    clearFilters = () => {
        let filterValues = this.state.filterValues
        let filters = this.props.filters
        for (let i = 0; i < filters.length; i++) {
            if (filters[i].showFilter)
                delete filterValues[filters[i].name]
        }
        this.setState({ filterValues })
        this.props.setFilterValues(filterValues)
        this.props.handleClose()
    }

    enableFilter = (filterName, filterType) => {
        let filterValues = this.state.filterValues
        if (filterType === "boolean") {
            filterValues[filterName] = true
        } else {
            filterValues[filterName] = null
        }
        this.setState({ filterValues })
    }

    applyFilters = () => {
        let filters = this.state.filterValues
        for (let filter in filters) {
            if (filters[filter] === null || filters[filter] === "" || filters[filter].length === 0) {
                delete filters[filter]
            }
        }
        this.props.setFilterValues(filters)
        this.props.handleClose()
    };

    setRangeFilter = (filterName, name, value, minValue, maxValue) => {
        let filterValues = this.state.filterValues
        let otherKey = (name.includes("min") ? "max" : "min") + name.substring(3)
        let range = filterName in filterValues && filterValues[filterName] !== null ? { ...filterValues[filterName], [name]: value } : { [name]: value, [otherKey]: name.includes("min") ? maxValue : minValue }
        this.setState({ filterValues: { ...filterValues, [filterName]: range } })
    }

    handleEnterKeyPress = (event, filter) => {
        if (event.key === "Enter") {
            event.preventDefault()
            if (filter.type === "boolean") {
                this.handleCheckboxChange(filter.name)
            } else {
                this.applyFilters()
            }
        }
    }

    handleCheckboxChange = (filterName) => {
        this.setState(prevState => ({
            filterValues: {
                ...prevState.filterValues,
                [filterName]: !prevState.filterValues[filterName]
            }
        }))
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleGlobalKeyPress)
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleGlobalKeyPress)
    }

    handleGlobalKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            this.applyFilters()
        } else if (event.key === "Escape") {
            event.preventDefault()
            this.props.handleClose()
        }
    }

    updateFilterValue = (filterName, value) => {
        let filterValues = this.state.filterValues
        filterValues[filterName] = value
        this.setState({ filterValues })
    }

    render() {
        return (
            <Popover className="listing-filters" placement={this.state.placement} target={this.props.target} isOpen={this.props.open} toggle={this.applyFilters} onKeyDown={(event) => this.handleEnterKeyPress(event, this.props.filters)} >
                <PopoverHeader>
                    <Row>
                        <Col xs="6">
                            <a className="text-danger" onClick={this.clearFilters}>
                                Clear
                            </a>
                        </Col>
                        <Col xs="6" className="text-right">
                            <a className="text-primary" onClick={this.applyFilters}>
                                Done
                            </a>
                        </Col>
                    </Row>
                </PopoverHeader>
                <PopoverBody>
                    {this.props.filters && this.props.filters.filter(item => item.showFilter).map((filter, idx) =>
                        <div className="filter__wrapper" key={idx}>
                            {this.isFilterEnabled(filter.name) ?
                                <i onClick={() => this.disableFilter(filter.name)} className="fa fa-lg fa-check-square-o"></i>
                                : <i onClick={() => !filter.isDisabled ? this.enableFilter(filter.name, filter.type) : null} className="fa fa-lg fa-square-o"></i>
                            }
                            <span className="filter-title">{filter.title}</span>
                            {this.isFilterEnabled(filter.name) && (filter.type == "select" ?
                                <Select
                                    className="bos-custom-select mt-2 mb-2"
                                    classNamePrefix="bos-select"
                                    name={filter.name}
                                    options={filter.options}
                                    placeholder={filter.placeholder}
                                    defaultValue={this.props.filterValues && this.props.filterValues[filter.name] && this.props.filterValues[filter.name] !== null && filter.options &&
                                        (
                                            !filter.isMulti
                                                ? filter.options.find(option => option.value === this.props.filterValues[filter.name])
                                                : filter.options.filter(option => this.props.filterValues[filter.name].includes(option.value) || this.props.filterValues[filter.name] == option.value || (Array.isArray(this.props.filterValues[filter.name]) && option.value[0] === this.props.filterValues[filter.name][0]))
                                        )
                                    }
                                    isLoading={!filter.options}
                                    onChange={options => filter.handleSelection ? filter.handleSelection(options) : this.updateFilterValue(filter.name, options ? options && filter.isMulti ? options.map(option => option.value) : options.value : null)}
                                    isMulti={filter.isMulti} isClearable
                                />
                                : filter.type == "boolean" ?
                                    <>
                                        <div>
                                            <span>{filter.optionOne}</span>
                                            <div className="toggle-switch">
                                                <input
                                                    type="checkbox"
                                                    className="toggle-switch-checkbox"
                                                    name={filter.name}
                                                    id={filter.name}
                                                    checked={!this.state.filterValues[filter.name]}
                                                    onChange={() => this.updateFilterValue(filter.name, !this.state.filterValues[filter.name])}
                                                />
                                                <label className="toggle-switch-label" htmlFor={filter.name}>
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                            <span>{filter.optionTwo}</span>
                                        </div>
                                    </>
                                    : filter.type == "date" ?
                                        <Input type="date" name={filter.name} id={filter.name} onChange={(e) => this.updateFilterValue(e.target.name, e.target.value)} value={this.state.filterValues[filter.name]} />
                                        :
                                        filter.type == "range" ?
                                            <MultiRangeSlider
                                                min={filter.min}
                                                max={filter.max}
                                                minLabel={filter.minLabel} maxLabel={filter.maxLabel}
                                                defaultValue={this.props.filterValues && this.props.filterValues && this.props.filterValues["yearRange"] ? [this.props.filterValues["yearRange"][filter.minLabel], this.props.filterValues["yearRange"][filter.maxLabel]] : [filter.min, filter.max]}
                                                onChange={(name, value) => this.setRangeFilter(filter.name, name, value, filter.min, filter.max)}
                                            />
                                            : <Input
                                                className="mt-2 mb-2" type={filter.type} name={filter.name} placeholder={filter.placeholder}
                                                defaultValue={this.props.filterValues && this.props.filterValues[filter.name]}
                                                onChange={(e) => this.updateFilterValue(e.target.name, e.target.value)}
                                            />
                            )}
                        </div>
                    )}
                </PopoverBody>
            </Popover>
        )
    }
}

export default compose(
    withApollo,
)(ListingFilters)
