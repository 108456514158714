import React from 'react'
import { Input } from 'reactstrap'
import { hasIssuedAndExpirationDates } from '../DocumentModal/utils'

function DocumentDataInput({ field, handleDriverDataChange }) {
    const hasIssuedExpirationDates = hasIssuedAndExpirationDates(field)

    return (
        <Input
            type={field.fieldType}
            name={field.fieldCode}
            value={field.value || ''}
            onChange={(e) => {
                switch (true) {
                    case field.fieldCode.startsWith("ZIPCODE") || field.fieldCode === "TRIPS" || field.fieldCode === "YEARS_ON_PLATFORM":
                        // Handle ZIPCODE, TRIPS, YEARS_ON_PLATFORM cases (numbers only)
                        handleDriverDataChange(e, true)
                        break
                    case field.fieldCode === "RATING":
                        // Handle RATING case (numbers and dot)
                        handleDriverDataChange(e, false, true)
                        break
                    default:
                        // Default case (alphanumeric only)
                        handleDriverDataChange(e)
                }
            }}
            // Need to have max/min exceptions for different type of dates
            max={field.fieldCode === 'DATE_OF_BIRTH' ||
                field.fieldCode === 'ISSUED_DATE' ||
                field.fieldCode === 'ANTIQUITY'
                ? new Date().toISOString().split('T')[0] : undefined}
            min={hasIssuedExpirationDates && field.fieldCode === 'EXPIRATION_DATE'
                ? new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]
                : field.fieldCode === 'EXPIRATION_DATE'
                    ? new Date().toISOString().split('T')[0]
                    : undefined
            }
            style={{
                backgroundColor: field.value && field.value.toString().trim() !== "" ? '' : 'rgba(255, 0, 0, 0.1)'
            }}
        />
    )
}

export default DocumentDataInput
