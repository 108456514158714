import gql from 'graphql-tag'

const AllBranches = gql`query AllBranches{
    allBranches{
        edges{
            node{
                id
                customId
                name
            }
        }
    } 
}`
const AgreementsQuery = gql`query AgreementsQuery($cursor:String, $first:Int!, $driverId:ID!) {
    rentals: allRentals(first:$first, after:$cursor, driverId:$driverId, orderBy:["-start_date"]){
        edges{
            node{
                id
                pk
                weeklyCharge
                startDate
                stage
                baseCharge
                insuranceUpcharge
                discount
                agreementType
                endDate
                notes
                promotionType
                branch{
                    id
                    name
                }
                splitAgreement{
                    id
                    driver{
                        id
                        name
                    }
                }
                carreturn: currentReturn{
                    id
                    pk
                    stage
                    scheduledDate
                    closedDate
                    dateAdded
                    returnType
                    returnCategory
                    returnReason
                    actualReturnReason
                    returnStage
                    driverPresent
                    nextPickupDate
                    gas
                    isScheduled
                    mileage
                    damage
                    notes
                    driverNotes
                    representative{
                        id
                        username
                        firstName
                        lastName
                    }
                    returnCondition{
                        id
                        gas
                        mileage
                        headlightsWorking
                        carpicturesSet{
                            edges{
                                node{
                                id
                                pictureUrl
                                pictureType
                                description
                                }
                            }
                        }
                    }
                }
                carreturnSet{
                    edges {
                        node {
                            id
                            autoDepositRefund
                            dateAdded
                            returnType
                            returnCategory
                            returnReason
                            scheduledDate
                            cancelledDate
                            stage
                            representative{
                                id
                                username
                                firstName
                                lastName
                            }
                        }
                    }
                }
                type{
                    id
                    name
                }
                agreementpriceSet{
                    edges{
                        node{
                            price
                            interval
                            intervalUnit
                            currency { code symbol }
                            id
                            isActive
                            startDate
                            createdBy {
                                id
                                username
                                firstName
                                lastName
                            }
                            updatedBy {
                                id
                                username
                                firstName
                                lastName
                            }
                            tierType
                            tiers{
                                edges{
                                    node{
                                        id
                                        price
                                        interval
                                        intervalUnit
                                        currency { code symbol }
                                        upperLimit
                                        upperLimitUnit
                                    }
                                }
                            }
                        }
                    }
                }
                agreementpromoSet{
                    edges{
                        node{
                            promoPrice
                            interval
                            intervalUnit
                            currency { code symbol }
                            id
                            isCurrentlyAcive
                            isPercentage
                            startDate
                            createdBy {
                                id
                                username
                                firstName
                                lastName
                            }
                            updatedBy {
                                id
                                username
                                firstName
                                lastName
                            }
                        }
                    }
                }
                history{
                    user
                    date
                    comment
                }
                pickupCondition{
                    gas
                    mileage
                    headlightsWorking
                    dateAdded
                    formData
                    carpicturesSet{
                        edges{
                            node{
                                id
                                dateAdded
                                pictureUrl
                                pictureType
                                description
                            }
                        }
                    }
                }
                returnCondition{
                    id
                    formData
                }
                representative{
                    id
                    username
                    firstName
                    lastName
                }
                driver{
                    id
                    name
                    tlcLicense
                }
                car{
                    id
                    pk
                    branch{
                        id
                        name
                    }
                    carModel{
                        id
                        make
                        name
                        series
                        groupType{
                            id
                            name
                        }
                    }
                    year
                    color
                    location
                    dmvPlate
                    fhv
                    vin
                    trackerStatus
                    hasTracker
                    trackers{
                        trackingId
                        trackerType
                    }
                    notes
                    activePolicy {
                        id     
                        insurancePolicy {
                            id
                            policyNumber
                            broker {
                                id
                                name
                            }
                        }
                    }
                    equipmentSet(first:10,removed:false){
                        edges {
                            node {
                              id
                              dateAdded
                              removed
                              customDeviceId
                              equipmentItems{
                                id
                                pk
                                name
                                equipmentItemsGroup {
                                  id
                                  name
                                }
                              }
                            }
                          }
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`
const InvoiceGroupsQuery = gql`query InvoiceGroupsQuery($driverId: ID!){
    invoiceGroups(driverId: $driverId){
        invoiceBalance
        chargeType
        chargeTypeDisplay
        totalCount
        totalUnpaidCount
    }
}`
const InvoicesQuery = gql`query TransactionsQuery($cursor: String,$first:Int!,$driverId:ID!) {
    invoices: allInvoices(first:$first,after: $cursor,driverId:$driverId){
        edges{
            node{
                id
                startDate
                uniqueId
                previousBalance
                totalBalance
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } }`
const CarlessmilesDiscountQuery = gql`query CarlessmilesDiscountQuery($cursor: String,$first:Int!,$driverId: ID!){
    allCarlessmilesDiscount(first:$first,after: $cursor,orderBy:["-date_recorded"],driverId: $driverId){
        edges{
            node{
                id
                dateRecorded
                mileageDifference
                car {
                    id
                    pk
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const UberTripsQuery = gql`query UberTripsQuery($cursor: String,$first:Int!,$driverId: ID!){
    allUberTrips(first:$first,after: $cursor,driverId: $driverId){
        edges{
            node{
                id
                tripWeek
                vin
                tripCount
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const StripeCards = gql`query StripeCards($id: ID!){
    cards: getStripeCards(id: $id){
        id
        cards {
            id
        }
    } 
}`

const insuranceStatusEnums = gql`query Types{
    driverInsuranceStatusEnums:__type(name: "DriverInsuranceStatusStatus") {
       states: enumValues {
           name
           description
       }
    }
   
}`

const AllExternalIdentifiers = gql`query AllExternalSystemIdentifiers($customerId: ID!){
    allExternalSystemIdentifiers(customerId: $customerId){
        id
        pk
        dateAdded
        dateModified
        externalId
        notes
        isDefault
        isArchived
        systemName
    } 
}`

const DriverDetailQuery = gql`query Driver($id: ID!, $settingsKeyNames: [String]){
    driver(id: $id){
        id
        pk
        preferredLanguage
        canDelete
        name
        firstName
        lastName
        gender
        email
        dob
        currentCharge
        phone
        tlcLicense
        dmvLicense
        taxId
        stripeId
        balance
        bcBalance
        balanceWriteOff
        creditWriteOff
        notes
        deposit
        city
        state 
        zipCode
        streetAddress
        isTlc
        folderId
        dateAdded
        argyleAccountId
        palencaUserId
        allowNotification
        mobileappdriver {
            id
            active
            activeDeviceId
            activeDeviceOs
            lastLogin
        }
        geoLocation {
            latitude
            longitude
            timestamp
        }
        blockedcustomerSet {
            edges {
              node {
                id
                pk
                dateAdded
                isArchived
                archivedDate
                archivedBy {
                    id
                    username
                    firstName
                    lastName
                }
                blockedBy {
                  id
                  username
                  firstName
                  lastName
                }
                reason {
                  id
                  name
                  description
                }
                notes
              }
            }
          }
        customerhandleSet(includeDeleted:true) {
            edges {
                node {
                    id
                    pk
                    type
                    title
                    handle
                    isPrimary
                    isArchived
                    isDuplicate
                }
            }
        }
        branch{
            id
            customId
            name
            country
            branchSettings(key_Name_In: $settingsKeyNames) {
              edges {
                node {
                  id
                  value
                  key {
                    id 
                    name
                  }
                }
              }
            }
            tenant{
                id
                customId
            }
        }
        driverRecord{
            name
            value
        }
        customerRating {
            weekendDate
            percentile
            trafficLightRating
        }
        currentAgreement{
            id
            agreementType
            startDate
            endDate
            car{
                id
                pk
                year
                vin
                carModel{
                    id
                    make
                    name
                    series
                }
            }
            agreementpriceSet{
                edges{
                    node{
                        id
                        price
                        interval
                        intervalUnit
                        isActive
                    }
                }
            }
        }
        driverbasestatusSet{
            edges{
                node{
                    id
                    status
                    base
                    otherDetails
                }
            }
        }
        reservationDriver(last:1,status:"Open"){
            edges{
              node{
                id
                pickupDate
                pickupType
                depositScheme
                depositAmount
                surcharge
                car{
                    id
                    pk
                    year
                    vin
                    carModel{
                        id
                        make
                        name
                        series
                    }
                }
              }
            }
        }
        tags{
            id
            name
            color
        }
        addressSet(isArchived:false) { 
            edges { 
                node { 
                    id 
                    title
                    addressLine1
                    addressLine2
                    country
                    fullAddress
                    city
                    state
                    postalCode
                    isArchived
                    fullAddress
                } 
            }
        }
        history{
            date
            comment
            user
          }
        metadata
    }
}`

const PaymentScheduleQuery = gql`
    query PaymentScheduleQuery($id: ID!){
        paymentSchedule(id: $id){
        id
        amountToCharge
        scheduleDatetime
        status
        notes
        canceledBy{
            id
            username
            firstName
            lastName
        }
        createdBy{
            id
            username
            firstName
            lastName
        }
        driver{
            id
        }
    }
} `

const CustomerIdentifiers = gql`
  query GetCustomerIdentifiers($customerId: ID, $status: String) {
  customerIdentifiers(customerId: $customerId, status: $status) {
   id
    value
    status
    dateAdded
    issuedDate
    expirationDate
    identifierType{
      typeName
    }
    referenceDocument{
      documentUrl
      name
    }
    metadata
  }
}
`

export { AllBranches, AgreementsQuery, InvoiceGroupsQuery, InvoicesQuery, insuranceStatusEnums, DriverDetailQuery, UberTripsQuery, CarlessmilesDiscountQuery, StripeCards, PaymentScheduleQuery, CustomerIdentifiers, AllExternalIdentifiers }
