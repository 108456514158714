import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Form, Alert, FormText } from 'reactstrap'
import Select from 'react-select'
import moment from "moment"
import toTitleCase from "../../Functions/toTitleCase"
import { TIMEZONES } from "../../Constants"


const BranchSettingKeyQuery = gql`query AllBranchSettingKeys{
    allBranchSettingKeys {
      edges {
        node {
          id
          name
          description
          type
          isRequired
        }
      }
    }
}`

const UpdateCreateBranchSettingsMutation = gql`mutation UpdateCreateBranchSettingsMutation($input: UpdateCreateBranchSettingsMutationInput!){
    updateOrCreateBranchSetting(input:$input){
        ok
        errors{
            field
            messages
        }
    }
} `

class CreateUpdateSetting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: { branchId: this.props.branchId },
            loading: false
        }
    }

    handleChange = (e) => {
        e.preventDefault()
        let { input } = this.state
        input[e.target.name] = e.target.value
        if (e.target.name === "keyId") {
            input["value"] = null
        }
        this.setState({ input })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        this.props.client.mutate({
            mutation: UpdateCreateBranchSettingsMutation,
            variables: {
                input: { ...this.state.input }
            }
        }).then(({ data }) => {
            this.setState({ loading: false })
            if (data.updateOrCreateBranchSetting.ok) {
                this.setState({ input: {} })
                this.props.refetchQuery()
                this.props.handleClose()
            } else if (data.updateOrCreateBranchSetting && data.updateOrCreateBranchSetting.errors) {
                this.setState({ loading: false, error: String(data.updateOrCreateBranchSetting.errors[0].messages) })
            } else {
                this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
            }
        }).catch((error) => {
            this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
        })
    }

    render() {
        return (
            <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                <PopoverHeader onClick={this.props.handleClose}>Create/Update Branch Settings <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                <PopoverBody>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    <Form>
                        <FormGroup>
                            <Label for="keyId">Key</Label>
                            <Input type="select" name="keyId" id="keyId" onChange={this.handleChange}>
                                <option value="">Select</option>
                                {this.props.allBranchSettingKeys && this.props.allBranchSettingKeys.edges && this.props.allBranchSettingKeys.edges.map(({ node }) => (
                                    <option value={node.id}>{toTitleCase(node.name)}{node.isRequired && "*"}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Value</Label>
                            {this.state.input && this.state.input.keyId ?
                                (this.props.allBranchSettingKeys && this.props.allBranchSettingKeys.edges && this.props.allBranchSettingKeys.edges.find(({ node }) => node.id === this.state.input.keyId).node.type !== "BOOLEAN" && this.props.allBranchSettingKeys.edges.find(({ node }) => node.id === this.state.input.keyId).node.name !== "PREFERRED_TIMEZONE" ?
                                    <Input type={this.props.allBranchSettingKeys.edges.find(({ node }) => node.id === this.state.input.keyId).node.type === "STRING" ? "text" : "number"} name="value" id="value" onChange={this.handleChange} /> :
                                    this.props.allBranchSettingKeys.edges.find(({ node }) => node.id === this.state.input.keyId).node.name === "PREFERRED_TIMEZONE" ?
                                        <Input type="select" name="value" id="value" defaultValue={""} onChange={this.handleChange}>
                                            <option value="" disabled>--- Select Timezone ----</option>
                                            {TIMEZONES.map(zone => <option value={zone.value}>{moment.tz(zone.label).format('zz') + " | " + zone.label}</option>)}
                                        </Input> :
                                        <Input type="select" name="value" id="value" defaultValue={""} onChange={this.handleChange}>
                                            <option value="" disabled>--- Select Option ----</option>
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </Input>
                                ) :
                                <Input type="text" name="value" id="value" disabled />
                            }
                            <FormText>{this.state.input && this.state.input.keyId && this.props.allBranchSettingKeys && this.props.allBranchSettingKeys.edges && this.props.allBranchSettingKeys.edges.find(({ node }) => node.id === this.state.input.keyId).node.description}</FormText>
                        </FormGroup>
                        {this.state.loading ? <Progress animated color="info" value={100} /> :
                            <Button type="submit" color="primary" onClick={this.handleSubmit}>Submit</Button>
                        }
                    </Form>
                </PopoverBody>
            </Popover>
        )
    }
}

export default compose(
    withApollo,
    graphql(BranchSettingKeyQuery, { props({ data: { allBranchSettingKeys } }) { return { allBranchSettingKeys } } }),
)(CreateUpdateSetting)
