import React from 'react'
import { Button, Modal, ModalBody } from "reactstrap"

const ViewPDFDocument = ({ doc, open, title, handleClose }) => {

    const handleDownload = (doc) => {
        const byteCharacter = atob(doc)
        const byteNumbers = new Array(byteCharacter.length).fill(0).map((_, i) =>
            byteCharacter.charCodeAt(i)
        )
        const byteArray = new Uint8Array(byteNumbers)

        const blob = new Blob([byteArray], { type: "application/pdf" })

        const blobUrl = URL.createObjectURL(blob)

        if (typeof window !== "undefined" && doc) {
            const downloadLink = document.createElement("a")
            downloadLink.href = blobUrl
            downloadLink.download = 'downloaded_file'
            document.body.appendChild(downloadLink)

            downloadLink.click()

            downloadLink.remove()

            URL.revokeObjectURL(blobUrl)
        }

    }


    return (
        <Modal
            className="DocViewerModal"
            isOpen={open}
            toggle={() => handleClose()}
            fullscreen
        >
            <div className="DocViewerModalHeader">
                <p className="DocViewerModalTitle">
                    {title}
                </p>
                <div className="DocViewerModalActions">
                    <p><i className="fa fa-download" onClick={() => handleDownload(doc)}></i></p>
                    <p><i className="fa fa-times" onClick={() => handleClose()}></i></p>
                </div>
            </div>
            <ModalBody>
                <iframe scrolling="yes" className="DocViewerFrame" src={`data:application/pdf;base64,${doc}`} />
            </ModalBody>
        </Modal>
    )
}

export default ViewPDFDocument