import { US_STATES, MX_STATES, MX_CITIES, US_CITIES } from "./Constants"

export function getStatesByCountry(countryCode) {
    const statesMap = {
        US: US_STATES,
        MX: MX_STATES,
    }

    if (countryCode === "ALL") {
        return Object.entries(statesMap).flatMap(([country, stateList]) =>
            Object.entries(stateList).map(([stateCode, stateName]) => ({
                value: stateCode,
                label: `${country} - ${stateCode} - ${stateName}`,
            }))
        )
    }

    return statesMap[countryCode] || US_STATES
}

export function getStateOptions(states) {
    return Object.keys(states).map((stateCode) => ({
        value: stateCode,
        label: `${stateCode} - ${states[stateCode]}`,
    }))
}

export function getCitiesByState(stateCode, countryCode) {
    const citiesMap = {
        US: US_CITIES,
        MX: MX_CITIES,
    }

    const statesMap = {
        US: US_STATES,
        MX: MX_STATES,
    }

    const stateName = statesMap[countryCode] && statesMap[countryCode][stateCode]
    return stateName && citiesMap[countryCode] && citiesMap[countryCode][stateName]
        ? citiesMap[countryCode][stateName]
        : []
}

export function getCityOptions(cities) {
    return cities.map((city) => ({
        value: city,
        label: city,
    }))
}

