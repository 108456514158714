import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import { Container, Col, Row, Label, Input, Table, Button, UncontrolledTooltip } from 'reactstrap'
import Loader from "../../../Material/Loader"
import moment from "moment"
import UpdateBranchInfo from "../UpdateBranchInfo"
import Badge from "reactstrap/lib/Badge"
import toTitleCase from "../../../Functions/toTitleCase"
import { HasPermissionsQuery } from "../../../Functions/querys"

const BranchOverviewQuery = gql`query Branch($id: ID!){
    branch(id: $id){
        id
        name
        defaultLanguage
        defaultMileageUnit
        tenant{
            name
            logoImage
        }
        legalEntity
        lineOfBusiness{
            id
            name
        }
        locationCode
        description
        defaultDeductible
        isActive
        customId
        dateAdded
        dateModified
        addressLine1
        addressLine2
        city
        stateCode
        postalCode
        country
        forTlc
        requiresInsurance
        allowCcSurcharge
        autoChargeCustomers
        autoDisableCustomers
        createdBy{
            id
            username
            firstName
            lastName
        }
        driverDocsRequiredForRental {
            edges {
                node {
                    id
                    typeName
                }
            }
        }
        carDocsRequiredForRental {
            edges {
                node {
                    id
                    typeName
                }
            }
        }
        carDocsRequiredForInspection {
            edges {
                node {
                    id
                    typeName
                }
            }
        }
        driverDocsRequiredForInsurance {
            edges {
                node {
                    id
                    typeName
                }
            }
        }
        driverDocsRequiredForApplication {
            edges {
                node {
                   id
                   typeName
                }
            }
        }
        driverDocTypes {
            edges {
                node {
                   id
                   typeName
                }
            }
        }
        transactionSettings {
            edges {
                node {
                    id
                    chargeType{
                        name
                    }
                    subChargeType{
                        name
                    }
                    isFixed
                    chargeAmount
                    chargeMinAmount
                    chargeMaxAmount
                    chargeInterval
                    createdBy{
                        id
                        username
                        firstName
                        lastName
                    }
                }
            }
        }
        returnLocation{
            id
            name
        }
        pickupLocation {
            id
            name
        }
    }
}`

class OverviewTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            openModal: "",
            forTlc: false,
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <Container fluid>
                {this.props.loading || !this.props.branch ? <Loader /> : <>
                    <Row>
                        <h4>&nbsp;&nbsp;&nbsp;BRANCH INFO</h4>
                        <Col xs={12} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col xs={3}>
                                    <Label>Date Added</Label>
                                    <Input disabled className="box-object-property-input" value={moment(this.props.branch.dateAdded).tz("America/New_York").format('lll')} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Date Modified</Label>
                                    <Input disabled className="box-object-property-input" value={moment(this.props.branch.dateModified).tz("America/New_York").format('lll')} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Created By</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.createdBy ? this.props.branch.createdBy.firstName ? this.props.branch.createdBy.firstName + " " + this.props.branch.createdBy.lastName : this.props.branch.createdBy.username : "--"} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Custom ID</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.customId} />
                                </Col>
                                <Col xs={3}>
                                    <Label id="branch-name">Branch Name <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchNameEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.name} />
                                    {this.state.openModal === 'branchNameEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Branch Name" name="name" value={this.props.branch.name}
                                        target="branch-name" type="text" open={this.state.openModal === 'branchNameEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="branch-address-line-1">Address Line 1 <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchAddressLine1Edit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.addressLine1} />
                                    {this.state.openModal === 'branchAddressLine1Edit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Branch Address Line 1" name="addressLine1" value={this.props.branch.addressLine1}
                                        target="branch-address-line-1" type="text" open={this.state.openModal === 'branchAddressLine1Edit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="branch-address-line-2">Address Line 2 <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchAddressLine2Edit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.addressLine2} />
                                    {this.state.openModal === 'branchAddressLine2Edit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Branch Address Line 2" name="addressLine2" value={this.props.branch.addressLine2}
                                        target="branch-address-line-2" type="text" open={this.state.openModal === 'branchAddressLine2Edit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="branch-city">City <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchCityEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.city} />
                                    {this.state.openModal === 'branchCityEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Branch City (Search Ex. New york)" name="city" value={this.props.branch.city}
                                        target="branch-city" type="select" open={this.state.openModal === 'branchCityEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>

                                <Col xs={3}>
                                    <Label id="branch-state-code">State <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchStateCodeEdit')} /> </Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.stateCode} />
                                    {this.state.openModal === 'branchStateCodeEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Branch Name" name="stateCode" value={this.props.branch.stateCode}
                                        target="branch-state-code" type="select" open={this.state.openModal === 'branchStateCodeEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label>Country</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.country} />
                                </Col>
                                <Col xs={3}>
                                    <Label id="branch-postal-code">Postal Code <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchPostalCodeEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.postalCode} />
                                    {this.state.openModal === 'branchPostalCodeEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Branch Postal Code" name="postalCode" value={this.props.branch.postalCode}
                                        target="branch-postal-code" type="number" open={this.state.openModal === 'branchPostalCodeEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="branch-cdw">Default Collision Deductible <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchCdwEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={`$${this.props.branch.defaultDeductible}`} />
                                    {this.state.openModal === 'branchCdwEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Branch Default CDW" type="number" name="defaultDeductible" value={this.props.branch.defaultDeductible}
                                        target="branch-cdw" open={this.state.openModal === 'branchCdwEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="branch-return-location">Return Location <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchReturnLocationEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.returnLocation ? this.props.branch.returnLocation.name : "--"} />
                                    {this.state.openModal === 'branchReturnLocationEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Return Location" name="returnLocationId" value={this.props.branch.returnLocation}
                                        target="branch-return-location" type="select" open={this.state.openModal === 'branchReturnLocationEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="branch-pickup-location">Pickup Location <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchPickupLocationEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.pickupLocation ? this.props.branch.pickupLocation.name : "--"} />
                                    {this.state.openModal === 'branchPickupLocationEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Pickup Location" name="pickupLocationId" value={this.props.branch.pickupLocation}
                                        target="branch-pickup-location" type="select" open={this.state.openModal === 'branchPickupLocationEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label>For TLC <i id="forTlc" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('forTlc')} /> </Label>
                                    {this.state.openModal === 'forTlc' && <UpdateBranchInfo branchId={this.props.branch.id} title="For TLC" name="forTlc" value={this.props.branch.forTlc}
                                        target="forTlc" type="boolean" open={this.state.openModal === 'forTlc'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="forTlc"
                                            id="forTlc"
                                            checked={!this.props.branch.forTlc}
                                            disabled
                                        />
                                        <label className="toggle-switch-label" htmlFor="forTlc">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </Col>
                                <Col xs={3}>
                                    <Label>Allow CC Surcharge <i id="allowCcSurcharge" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('allowCcSurcharge')} /></Label>
                                    {this.state.openModal === 'allowCcSurcharge' && <UpdateBranchInfo branchId={this.props.branch.id} title="Allow Surcharge" name="allowCcSurcharge" value={this.props.branch.allowCcSurcharge}
                                        target="allowCcSurcharge" type="boolean" open={this.state.openModal === 'allowCcSurcharge'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="allowCcSurcharge"
                                            id="allowCcSurcharge"
                                            checked={!this.props.branch.allowCcSurcharge}
                                            disabled
                                        />
                                        <label className="toggle-switch-label" htmlFor="allowCcSurcharge">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </Col>
                                <Col xs={3}>
                                    <Label>Auto Charge Customers <i id="autoChargeCustomers" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('autoChargeCustomers')} /></Label>
                                    {this.state.openModal === 'autoChargeCustomers' && <UpdateBranchInfo branchId={this.props.branch.id} title="Auto Charge Customers" name="autoChargeCustomers" value={this.props.branch.autoChargeCustomers}
                                        target="autoChargeCustomers" type="boolean" open={this.state.openModal === 'autoChargeCustomers'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="autoChargeCustomers"
                                            id="autoChargeCustomers"
                                            checked={!this.props.branch.autoChargeCustomers}
                                            disabled
                                        />
                                        <label className="toggle-switch-label" htmlFor="autoChargeCustomers">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </Col>
                                <Col xs={3}>
                                    <Label>Requires Insurance <i id="requiresInsurance" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('requiresInsurance')} /></Label>
                                    {this.state.openModal === 'requiresInsurance' && <UpdateBranchInfo branchId={this.props.branch.id} title="Require Insurance" name="requiresInsurance" value={this.props.branch.requiresInsurance}
                                        target="requiresInsurance" type="boolean" open={this.state.openModal === 'requiresInsurance'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="requiresInsurance"
                                            id="requiresInsurance"
                                            disabled
                                            checked={!this.props.branch.requiresInsurance}
                                        />
                                        <label className="toggle-switch-label" htmlFor="requiresInsurance">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </Col>
                                <Col xs={3}>
                                    <Label>Legal Entity <i id="legalEntityEdit" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('legalEntityEdit')} /></Label>
                                    {this.state.openModal === 'legalEntityEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Require Insurance" name="legalEntity" value={this.props.branch.legalEntity}
                                        target="legalEntityEdit" type="select" open={this.state.openModal === 'legalEntityEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <Input disabled className="box-object-property-input" value={this.props.branch.legalEntity} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Line of Business <i id="lineOfBusinessEdit" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('lineOfBusinessEdit')} /></Label>
                                    {this.state.openModal === 'lineOfBusinessEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Update Line of Business" name="lineOfBusinessId" value={this.props.branch.lineOfBusiness ? this.props.branch.lineOfBusiness.id : ""}
                                        target="lineOfBusinessEdit" type="select" open={this.state.openModal === 'lineOfBusinessEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <Input disabled className="box-object-property-input" value={this.props.branch.lineOfBusiness ? toTitleCase(this.props.branch.lineOfBusiness.name) : ""} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Location Code <i id="locationCodeEdit" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('locationCodeEdit')} /></Label>
                                    {this.state.openModal === 'locationCodeEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Location Code" name="locationCode" value={this.props.branch.locationCode}
                                        target="locationCodeEdit" type="text" open={this.state.openModal === 'locationCodeEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <Input disabled className="box-object-property-input" value={this.props.branch.locationCode} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Mileage Unit <i id="mileageUnitEdit" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('mileageUnitEdit')} /></Label>
                                    {this.state.openModal === 'mileageUnitEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Update Mileage Unit" name="defaultMileageUnit" value={this.props.branch.defaultMileageUnit ? this.props.branch.defaultMileageUnit : ""}
                                        target="mileageUnitEdit" type="select" open={this.state.openModal === 'mileageUnitEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <Input disabled className="box-object-property-input" value={this.props.branch.defaultMileageUnit} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Language <i id="languageEdit" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('languageEdit')} /></Label>
                                    {this.state.openModal === 'languageEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Update Default Language" name="defaultLanguage" value={this.props.branch.defaultLanguage ? this.props.branch.defaultLanguage : ""}
                                        target="languageEdit" type="select" open={this.state.openModal === 'languageEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <Input disabled className="box-object-property-input" value={this.props.branch.defaultLanguage} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Is Active {this.props.hasPermissions.includes("custom_can_update_branch_status") && <i id="isActive" className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('isActive')} />}</Label>
                                    {this.state.openModal === 'isActive' && <UpdateBranchInfo branchId={this.props.branch.id} title="Require Insurance" name="isActive" value={this.props.branch.isActive}
                                        target="isActive" type="boolean" open={this.state.openModal === 'isActive'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="isActive"
                                            id="isActive"
                                            disabled
                                            checked={!this.props.branch.isActive}
                                        />
                                        <label className="toggle-switch-label" htmlFor="isActive">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </Col>
                                <Col xs={3}>
                                    <Label>Tenant</Label>
                                    <p className="box-object-property-input text-uppercase">{this.props.branch.tenant && this.props.branch.tenant.name} - {this.props.branch.tenant && this.props.branch.tenant.logoImage && <img width={50} src={this.props.branch.tenant.logoImage} alt={this.props.branch.tenant && this.props.branch.tenant.name + " Logo"} />}</p>
                                </Col>
                                <Col xs={9}>
                                    <Label id="branch-description">Description <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('branchDescriptionEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.branch.description} />
                                    {this.state.openModal === 'branchDescriptionEdit' && <UpdateBranchInfo branchId={this.props.branch.id} title="Edit Branch Description" name="description" value={this.props.branch.description}
                                        target="branch-description" type="text" open={this.state.openModal === 'branchDescriptionEdit'} handleClose={() => this.toggleModal("")} refetchBranchQuery={this.props.refetchQuery} />}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <h4>&nbsp;&nbsp;&nbsp;ALLOWED DOCS</h4>
                        <Col xs={12}>
                            <Row>
                                <Col xs={6} className="bos-object-section-wrapper">
                                    <Row className="bos-object-section">
                                        <Col xs={12}>
                                            <Label>Driver Document Types</Label><br />
                                            {this.props.branch.driverDocTypes && this.props.branch.driverDocTypes.edges && this.props.branch.driverDocTypes.edges.length > 0 ?
                                                <>{this.props.branch.driverDocTypes.edges.map(item => <><Badge style={{ fontSize: "15px" }} color="primary">{item.node.typeName}</Badge>&nbsp;</>)} <Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-driver-docs" onClick={() => this.toggleModal('driverDocs')} /></Badge></> : <><p className="text-danger">No driver document types for this branch!</p><Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-driver-docs" onClick={() => this.toggleModal('driverDocs')} /></Badge></>}
                                            {this.state.openModal === 'driverDocs' &&
                                                <UpdateBranchInfo
                                                    branchId={this.props.branch.id}
                                                    title="Edit Driver Docs For Branch"
                                                    name="driverDocTypes"
                                                    value={this.props.branch.driverDocTypes}
                                                    target="branch-driver-docs"
                                                    type="select"
                                                    open={this.state.openModal === 'driverDocs'}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchBranchQuery={this.props.refetchQuery}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <h4>&nbsp;&nbsp;&nbsp;REQUIRED DOCS</h4>
                        <Col xs={12}>
                            <Row>
                                <Col xs={6} className="bos-object-section-wrapper">
                                    <Row className="bos-object-section">
                                        <Col xs={12}>
                                            <Label>Driver Documents Required For Application</Label><br />
                                            {this.props.branch.driverDocsRequiredForApplication && this.props.branch.driverDocsRequiredForApplication.edges && this.props.branch.driverDocsRequiredForApplication.edges.length > 0 ?
                                                <>{this.props.branch.driverDocsRequiredForApplication.edges.map(item => <><Badge style={{ fontSize: "15px" }} color="primary">{item.node.typeName}</Badge>&nbsp;</>)} <Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-driver-application-docs" onClick={() => this.toggleModal('branchDriverApplicationDocsEdit')} /></Badge></> : <><p className="text-danger">No documents required for driver application under this branch!</p><Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-driver-application-docs" onClick={() => this.toggleModal('branchDriverApplicationDocsEdit')} /></Badge></>}
                                            {this.state.openModal === 'branchDriverApplicationDocsEdit' &&
                                                <UpdateBranchInfo
                                                    branchId={this.props.branch.id}
                                                    title="Edit Driver Application Docs"
                                                    name="driverDocsRequiredForApplication"
                                                    value={this.props.branch.driverDocsRequiredForApplication}
                                                    target="branch-driver-application-docs"
                                                    type="select"
                                                    open={this.state.openModal === 'branchDriverApplicationDocsEdit'}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchBranchQuery={this.props.refetchQuery}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6} className="bos-object-section-wrapper">
                                    <Row className="bos-object-section">
                                        <Col xs={12}>
                                            <Label>Driver Documents Required for Insurance</Label><br />
                                            {this.props.branch.driverDocsRequiredForInsurance && this.props.branch.driverDocsRequiredForInsurance.edges && this.props.branch.driverDocsRequiredForInsurance.edges.length > 0 ?
                                                <>{this.props.branch.driverDocsRequiredForInsurance.edges.map(item => <><Badge style={{ fontSize: "15px" }} color="primary">{item.node.typeName}</Badge>&nbsp;</>)} <Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-driver-insurance-docs" onClick={() => this.toggleModal('branchDriverInsuranceDocsEdit')} /></Badge></> : <><p className="text-danger">No documents required for driver insurance under this branch!</p><Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-driver-insurance-docs" onClick={() => this.toggleModal('branchDriverInsuranceDocsEdit')} /></Badge></>}
                                            {this.state.openModal === 'branchDriverInsuranceDocsEdit' &&
                                                <UpdateBranchInfo
                                                    branchId={this.props.branch.id}
                                                    title="Edit Driver Insurance Docs"
                                                    name="driverDocsRequiredForInsurance"
                                                    value={this.props.branch.driverDocsRequiredForInsurance}
                                                    target="branch-driver-insurance-docs"
                                                    type="select"
                                                    open={this.state.openModal === 'branchDriverInsuranceDocsEdit'}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchBranchQuery={this.props.refetchQuery}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6} className="bos-object-section-wrapper">
                                    <Row className="bos-object-section">
                                        <Col xs={12}>
                                            <Label>Driver Documents Required for Rental</Label><br />
                                            {this.props.branch.driverDocsRequiredForRental && this.props.branch.driverDocsRequiredForRental.edges && this.props.branch.driverDocsRequiredForRental.edges.length > 0 ?
                                                <>{this.props.branch.driverDocsRequiredForRental.edges.map(item => <><Badge style={{ fontSize: "15px" }} color="primary">{item.node.typeName}</Badge>&nbsp;</>)} <Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-driver-rental-docs" onClick={() => this.toggleModal('branchDriverRentalDocsEdit')} /></Badge></> : <><p className="text-danger">No documents required for driver rental under this branch!</p><Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-driver-rental-docs" onClick={() => this.toggleModal('branchDriverRentalDocsEdit')} /></Badge></>}
                                            {this.state.openModal === 'branchDriverRentalDocsEdit' &&
                                                <UpdateBranchInfo
                                                    branchId={this.props.branch.id}
                                                    title="Edit Driver Rental Docs"
                                                    name="driverDocsRequiredForRental"
                                                    value={this.props.branch.driverDocsRequiredForRental}
                                                    target="branch-driver-rental-docs"
                                                    type="select"
                                                    open={this.state.openModal === 'branchDriverRentalDocsEdit'}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchBranchQuery={this.props.refetchQuery}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6} className="bos-object-section-wrapper">
                                    <Row className="bos-object-section">
                                        <Col xs={12}>
                                            <Label>Car Documents Required for Rental</Label><br />
                                            {this.props.branch.carDocsRequiredForRental && this.props.branch.carDocsRequiredForRental.edges && this.props.branch.carDocsRequiredForRental.edges.length > 0 ?
                                                <>{this.props.branch.carDocsRequiredForRental.edges.map(item => <><Badge style={{ fontSize: "15px" }} color="primary">{item.node.typeName}</Badge>&nbsp;</>)} <Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-car-rental-docs" onClick={() => this.toggleModal('branchCarRentalDocsEdit')} /></Badge></> : <><p className="text-danger">No documents required for car rental under this branch!</p><Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-car-rental-docs" onClick={() => this.toggleModal('branchCarRentalDocsEdit')} /></Badge></>}
                                            {this.state.openModal === 'branchCarRentalDocsEdit' &&
                                                <UpdateBranchInfo
                                                    branchId={this.props.branch.id}
                                                    title="Edit Car Rental Docs"
                                                    name="carDocsRequiredForRental"
                                                    value={this.props.branch.carDocsRequiredForRental}
                                                    target="branch-car-rental-docs"
                                                    type="select"
                                                    open={this.state.openModal === 'branchCarRentalDocsEdit'}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchBranchQuery={this.props.refetchQuery}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6} className="bos-object-section-wrapper">
                                    <Row className="bos-object-section">
                                        <Col xs={12}>
                                            <Label>Car Documents Required for Inspection</Label><br />
                                            {this.props.branch.carDocsRequiredForInspection && this.props.branch.carDocsRequiredForInspection.edges && this.props.branch.carDocsRequiredForInspection.edges.length > 0 ?
                                                <>{this.props.branch.carDocsRequiredForInspection.edges.map(item => <><Badge style={{ fontSize: "15px" }} color="primary">{item.node.typeName}</Badge>&nbsp;</>)} <Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-car-inspection-docs" onClick={() => this.toggleModal('branchCarInspectionDocsEdit')} /></Badge></> : <><p className="text-danger">No documents required for car inspection under this branch!</p><Badge style={{ fontSize: "15px" }} color="success"><i className="fa fa-plus fa-lg" aria-hidden="true" id="branch-car-inspection-docs" onClick={() => this.toggleModal('branchCarInspectionDocsEdit')} /></Badge></>}
                                            {this.state.openModal === 'branchCarInspectionDocsEdit' &&
                                                <UpdateBranchInfo
                                                    branchId={this.props.branch.id}
                                                    title="Edit Car Inspection Docs"
                                                    name="carDocsRequiredForInspection"
                                                    value={this.props.branch.carDocsRequiredForInspection}
                                                    target="branch-car-inspection-docs"
                                                    type="select"
                                                    open={this.state.openModal === 'branchCarInspectionDocsEdit'}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchBranchQuery={this.props.refetchQuery}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", marginBottom: "5px", marginRight: "5px" }}>
                        <Col className="d-flex justify-content-start">
                            <h4>TRANSACTION SETTINGS</h4>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button disabled={!this.props.hasPermissions.includes("add_transactionsetting")} size="sm" onClick={() => this.toggleModal('branchTransactionSettingsEdit')} className={"ghost"} id={"branchTransactionSettingsEdit"}>Add New Settings</Button>
                            {!this.props.hasPermissions.includes("add_transactionsetting") && <UncontrolledTooltip target="branchTransactionSettingsEdit">Not Enough Permissions To Perform This Action</UncontrolledTooltip>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                {/* <th>Date Added</th> */}
                                                <th>Charge Type</th>
                                                <th>Sub Charge Type</th>
                                                <th>Is Fixed</th>
                                                <th>Charge Amount</th>
                                                <th>Minimum Amount</th>
                                                <th>Maximum Amount</th>
                                                <th>Charge Interval</th>
                                                <th>Created By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.branch.transactionSettings && this.props.branch.transactionSettings.edges && this.props.branch.transactionSettings.edges.length > 0 ? this.props.branch.transactionSettings.edges.map(item =>
                                                <tr>
                                                    <td>
                                                        {item.node.chargeType.name}
                                                    </td>
                                                    <td>
                                                        {item.node.subChargeType ? item.node.subChargeType.name : "--"}
                                                    </td>
                                                    <td>
                                                        {item.node.isFixed ? "YES" : "NO"}
                                                    </td>
                                                    <td>
                                                        {item.node.chargeAmount || "--"}
                                                    </td>
                                                    <td>
                                                        {item.node.chargeMinAmount || "--"}
                                                    </td>
                                                    <td>
                                                        {item.node.chargeMaxAmount || "--"}
                                                    </td>
                                                    <td>
                                                        {item.node.chargeInterval}
                                                    </td>
                                                    <td>
                                                        {item.node.createdBy ? item.node.createdBy.firstName ? item.node.createdBy.firstName + " " + item.node.createdBy.lastName : item.node.createdBy.username : "--"}
                                                    </td>
                                                </tr>)
                                                : <tr className="text-danger"><td colSpan={6}>No fee settings found under this branch!</td></tr>
                                            }
                                        </tbody>

                                    </Table>
                                    {this.state.openModal === 'branchTransactionSettingsEdit' &&
                                        <UpdateBranchInfo
                                            branchId={this.props.branch.id}
                                            title="Edit Branch Transaction Settings"
                                            name="transactionSettings"
                                            value={this.props.branch.transactionSettings}
                                            target="branchTransactionSettingsEdit"
                                            type="select"
                                            open={this.state.openModal === 'branchTransactionSettingsEdit'}
                                            handleClose={() => this.toggleModal("")}
                                            refetchBranchQuery={this.props.refetchQuery}
                                        />}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>}
            </Container>
        )
    }
}


export default compose(
    withApollo,
    graphql(BranchOverviewQuery, {
        options: ({ branchId }) => ({ variables: { id: branchId } }),
        props({ data: { branch, loading, refetch, variables } }) {
            return {
                branch, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: BranchOverviewQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { branch: fetchMoreResult.branch }
                        },
                    })
                },
            }
        }
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_update_branch_status", "add_transactionsetting"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(OverviewTab)
