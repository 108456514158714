import React from "react"
import { Popover, PopoverHeader, PopoverBody, Row, Col, Label, Button } from "reactstrap"
import DatetimeRenderer from "../DatetimeRenderer"

const HISTORY_TABLE_HEADER = [
    { id: "date", name: "Date" },
    { id: "detail", name: "Details" },
    { id: "createdBy", name: "Created By" },
]

const DocumentHistoryPopover = ({
    isOpen,
    target,
    doc,
    handleClosePopover,
}) => {
    return (
        <Popover placement="top" isOpen={isOpen} target={target}>
            <PopoverHeader >Document History</PopoverHeader>
            <PopoverBody>
                <Row>
                    {HISTORY_TABLE_HEADER.map((tableHeader) => (
                        <Col key={tableHeader.id} xs={4}>
                            <Label>{tableHeader.name}</Label>
                        </Col>
                    ))}
                </Row>
                {doc.history && doc.history.length > 0 ? (
                    doc.history.map((history, index) => (
                        <div key={index}>
                            <Row>
                                <Col>
                                    <p>{history.date ? <DatetimeRenderer datetime={history.date} /> : "--"}</p>
                                </Col>
                                <Col>
                                    <p>{history.comment ? history.comment : "--"}</p>
                                </Col>
                                <Col>
                                    <p>{history.user ? history.user : "--"}</p>
                                </Col>
                            </Row>
                            <hr />
                        </div>
                    ))
                ) : (
                    <Row>
                        <Col>
                            <p>No Document History Found!</p>
                        </Col>
                    </Row>
                )}
                <Button type="button" size="sm" onClick={handleClosePopover}>
                    Close
                </Button>
            </PopoverBody>
        </Popover>
    )
}

export default DocumentHistoryPopover
