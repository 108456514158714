import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment-timezone'
import { FormText, Card, CardBody, CardText, Row, Col, Alert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Badge } from 'reactstrap'
import PriceList from '../Reservations/PriceList'
import './NewAgreementPrice.css'
import UpdateAgreementPlan from './UpdateAgreementPlan'
import UpdateAgreementPromoPlanModal from './UpdateAgreementPromoPlan'

const RentalDetailQuery = gql`query Rental($id: ID!){
    rental(id: $id){
        id
        stage
        car{
            id
        }
        branch{
            id
            tenant{
                id
                customId
            }
        }
        type{
            id
            chargeFrequency
            chargeFrequencyUnit
        }
        agreementpriceSet{
            edges{
                node{
                    id
                    price
                    minimumPrice
                    maximumPrice
                    interval
                    intervalUnit
                    currency { code symbol }
                    tierType
                    startDate
                    isActive
                    createdBy {
                        id
                        username
                        firstName
                        lastName
                    }
                    updatedBy {
                        id
                        username
                        firstName
                        lastName
                    }
                    tiers{
                        edges{
                            node{
                                id
                                price
                                interval
                                intervalUnit
                                currency { code symbol }
                                upperLimit
                                upperLimitUnit
                            }
                        }
                    }
                }
            }
        }

        agreementpromoSet{
            edges{
                node{
                    title
                    promoPrice
                    minimumPromoPrice
                    maximumPromoPrice
                    interval
                    intervalUnit
                    currency { code symbol }
                    id
                    startDate
                    expirationDate
                    isCurrentlyAcive
                    isPercentage
                    cycle
                    minCycle
                    maxCycle
                    createdBy {
                        id
                        username
                        firstName
                        lastName
                    }
                    updatedBy {
                        id
                        username
                        firstName
                        lastName
                    }
                }
            }
        }
    }
}`

const CreateAgreementPriceMutation = gql`
mutation createAgreementPriceMutation($input: CreateAgreementPriceMutationInput!){
    createAgreementPrice(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const CreateAgreementPromoMutation = gql`
mutation createAgreementPromoMutation($input: CreateAgreementPromoMutationInput!){
    createAgreementPromo(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const RemoveAgreementPromoMutation = gql`
mutation removeAgreementPromoMutation($input: RemoveAgreementPromoMutationInput!){
    removeAgreementPromo(input:$input){
        ok
        errors{
            messages
        }
    }
}`

const UpdateAgreementPromoMutation = gql`mutation updateAgreementPromoMutation($input: UpdateAgreementPromoMutationInput!){
    updateAgreementPromo(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`

const RemoveAgreementPriceMutation = gql`
mutation removeAgreementPriceMutation($input: RemoveAgreementPriceMutationInput!){
    removeAgreementPrice(input:$input){
        ok
        errors{
            messages
        }
    }
}`

const UpdateAgreementPriceMutation = gql`mutation updateAgreementPriceMutation($input: UpdateAgreementPriceMutationInput!){
    updateAgreementPrice(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`

class NewAgreementPrice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: "",
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name]: value })
    };

    refetchQuery = () => {
        this.props.refetchReservationQuery()
    }

    compareTemplatesDate = (a, b) => {
        if (a.node.startDate < b.node.startDate)
            return -1
        if (a.node.startDate > b.node.startDate)
            return 1
        return 0
    }

    handleClose = (e) => {
        let state = {
            loading: false,
            error: "",
        }
        this.setState({ ...state })
        this.props.handleClose()
        if (this.props.refetchQuery) {
            this.props.refetchQuery()
        }
    };

    updateAgreementPlan = (action = "add", plan, e) => {
        this.setState({ loading: true })
        let input
        if (action === "add") {
            if (((this.props.rental && this.props.rental.branch && this.props.rental.branch.tenant && this.props.rental.branch.tenant.customId === "mnave")) &&
                ((this.props.rental && this.props.rental.agreementpriceSet && this.props.rental.agreementpriceSet.edges && this.props.rental.agreementpriceSet.edges.length > 0))
            ) {
                this.setState({ error: "Unable to add a new pricing template. Only one pricing template can be active at a time.", loading: false })
                return
            }
            plan.startDate = moment().add(1, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE)
            input = {
                agreement: this.props.rental.id,
                price: plan.price,
                minimumPrice: plan.minimumPrice,
                maximumPrice: plan.maximumPrice,
                interval: plan.interval,
                intervalUnit: plan.intervalUnit.charAt(0).toUpperCase() + plan.intervalUnit.substr(1).toLowerCase(),
                startDate: plan.startDate,
                tierType: plan.tierType && plan.tiers.edges.length > 0 ? plan.tierType.charAt(0).toUpperCase() + plan.tierType.substr(1).toLowerCase() : null,
                tiers: plan.tierType && plan.tiers.edges.length > 0 && plan.tiers.edges.map(tier => { return tier.node.id })
            }
        } else if (action === "remove") {
            input = { agreementPrice: plan.id }
        } else if (action === "edit") {
            let { name, value } = e.target
            if (name.includes("selectedPlanStartDate"))
                input = {
                    agreementPrice: plan.id,
                    startDate: value
                }
            else if (name.includes("selectedPlanBasePrice"))
                input = {
                    agreementPrice: plan.id,
                    price: value
                }
        }
        if (action === "add" || action === "remove" || action === "edit") {
            this.props.client.mutate({
                mutation: action == "add" ? CreateAgreementPriceMutation : action == "remove" ? RemoveAgreementPriceMutation : action == "edit" ? UpdateAgreementPriceMutation : null,
                variables: { input },
            }).then((result) => {
                if (result && result.data &&
                    ((action === 'add' && result.data.createAgreementPrice && result.data.createAgreementPrice.ok) ||
                        (action === 'remove' && result.data.removeAgreementPrice && result.data.removeAgreementPrice.ok) ||
                        (action === 'edit' && result.data.updateAgreementPrice && result.data.updateAgreementPrice.ok))) {
                    this.refetchQuery()
                    this.setState({ loading: false, error: "" })
                } else {
                    let error = ""
                    if (result && result.data && action === 'add' && result.data.createAgreementPrice && result.data.createAgreementPrice.errors && result.data.createAgreementPrice.errors) {
                        result.data.createAgreementPrice.errors.map((item, i) => item.messages ? error += item.messages.join(". ") : "")
                    } else if (result && result.data && action === 'remove' && result.data.removeAgreementPriceremoveAgreementPricez && result.data.removeAgreementPrice.errors && result.data.removeAgreementPrice.errors) {
                        result.data.removeAgreementPrice.errors.map((item, i) => item.messages ? error += item.messages.join(". ") : "")
                    } else if (result && result.data && action === 'edit' && result.data.updateAgreementPrice && result.data.updateAgreementPrice.errors && result.data.updateAgreementPrice.errors) {
                        result.data.updateAgreementPrice.errors.map((item, i) => item.messages ? error += item.messages.join(". ") : "")
                    } else {
                        error = "An error has occured. Please contact admin."
                    }
                    this.setState({ loading: false, error: error })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occured. Please contact admin." })
            })
        }
    }

    updateAgreementPromoPlan = (action = "add", plan, e) => { // action can be add/remove/edit
        let input
        if (action === "edit") {
            let { name, value } = e.target
            if (name.includes("selectedPromoStartDate"))
                input = {
                    agreementPromo: plan.id,
                    startDate: value
                }
            else if (name.includes("selectedPromoPrice"))
                input = {
                    agreementPromo: plan.id,
                    promoPrice: value
                }
            else if (name.includes("selectedPromoExpirationDate"))
                input = {
                    agreementPromo: plan.id,
                    expirationDate: value
                }
        }
        else if (action === "add") {
            // plan.startDate = moment().add(1,'days').tz("America/New_York").format(moment.HTML5_FMT.DATE);

            input = {
                agreement: this.props.rental.id,
                title: plan.title,
                promoPrice: plan.promoPrice,
                minimumPromoPrice: plan.minimumPromoPrice,
                maximumPromoPrice: plan.maximumPromoPrice,
                cycle: plan.cycle,
                minCycle: plan.minCycle,
                maxCycle: plan.maxCycle,
                isPercentage: plan.isPercentage,
                interval: plan.interval,
                intervalUnit: plan.intervalUnit.charAt(0).toUpperCase() + plan.intervalUnit.substr(1).toLowerCase(),
                startDate: plan.startDate,
                expirationDate: plan.expirationDate
            }
        }
        else if (action === "remove") {
            input = {
                agreementPromo: plan.id,
            }
        }
        this.props.client.mutate({
            mutation: action == "add" ? CreateAgreementPromoMutation : action == "remove" ? RemoveAgreementPromoMutation : action == "edit" ? UpdateAgreementPromoMutation : null,
            variables: { input },
        }).then((result) => {
            if (result && result.data &&
                ((action === 'add' && result.data.createAgreementPromo && result.data.createAgreementPromo.ok) ||
                    (action === 'remove' && result.data.removeAgreementPromo && result.data.removeAgreementPromo.ok) ||
                    (action === 'edit' && result.data.updateAgreementPromo && result.data.updateAgreementPromo.ok))) {
                this.refetchQuery()
                this.setState({ loading: false, error: "" })
            } else {
                let error = ""
                if (result && result.data && action === 'add' && result.data.createAgreementPromo && result.data.createAgreementPromo.errors && result.data.createAgreementPromo.errors) {
                    result.data.createAgreementPromo.errors.map((item, i) => item.messages ? error += item.messages.join(". ") : "")
                } else if (result && result.data && action === 'remove' && result.data.removeAgreementPromo && result.data.removeAgreementPromo.errors && result.data.removeAgreementPromo.errors) {
                    result.data.removeAgreementPromo.errors.map((item, i) => item.messages ? error += item.messages.join(". ") : "")
                } else if (result && result.data && action === 'edit' && result.data.updateAgreementPromo && result.data.updateAgreementPromo.errors && result.data.updateAgreementPromo.errors) {
                    result.data.updateAgreementPromo.errors.map((item, i) => item.messages ? error += item.messages.join(". ") : "")
                } else {
                    error = "An error has occured. Please contact admin."
                }
                this.setState({ loading: false, error: error })
            }
        }).catch((err) => {
            this.setState({ loading: false, error: "An error has occured. Please contact admin." })
        })
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    //TODO: Agreement edit for promotions
    render() {
        const allTemplates = this.props.rental && this.props.rental.agreementpriceSet && this.props.rental.agreementpromoSet && this.props.rental.agreementpriceSet !== null &&
            this.props.rental.agreementpromoSet !== null && [...this.props.rental.agreementpriceSet.edges, ...this.props.rental.agreementpromoSet.edges]
        return (
            <div>
                <Modal className="NewAgreementPrice" isOpen={this.props.open}>
                    {this.props.rental &&
                        <>
                            <ModalHeader>Customize Agreement Pricing Plans</ModalHeader>
                            <ModalBody>
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}

                                <PriceList updatePlan={this.updateAgreementPlan} updatePromoPlan={this.updateAgreementPromoPlan} agreementTypeId={this.props.rental.type ? this.props.rental.type.id : ""} carId={this.props.rental.car ? this.props.rental.car.id : ""} />
                                {/* {this.props.allTemplates && this.props.allTemplates.edges.length > 0 && (this.props.allTemplates.edges).sort(this.compareTemplatesDate).map((agreementPlan,i)=> */}
                                {allTemplates && allTemplates.length > 0 && (allTemplates).sort(this.compareTemplatesDate).map((agreementPlan, i) =>

                                    <Col xs={12} key={i}>
                                        <Card className={agreementPlan.node.isActive ? "plan-detail-card" : "promo-detail-card"}>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={4}>
                                                        <FormGroup>
                                                            {agreementPlan.__typename == "AgreementPromoNodeEdge" &&
                                                                <>
                                                                    <Label for={"selectedPromoPrice" + i}>Promo Base {agreementPlan.node.isCurrentlyAcive && <Badge color="primary">Active</Badge>}
                                                                        {agreementPlan && agreementPlan.node && agreementPlan.node.startDate && moment().diff(agreementPlan.node.startDate, 'minutes') < 0 && <span id={"base" + i} className="pl-4">
                                                                            <i onClick={() => this.toggleModal("promoPriceEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                                        </span>}
                                                                    </Label>
                                                                    <p style={{ "fontSize": "1rem", "marginBottom": "auto" }}>
                                                                        <b>{agreementPlan.node.isPercentage ? agreementPlan.node.promoPrice + '%' : '$' + agreementPlan.node.promoPrice}</b>
                                                                    </p>
                                                                    <FormText className="mt-0">{agreementPlan.node.currency ? agreementPlan.node.currency.code.toUpperCase() : "USD"} per {agreementPlan.node.interval && agreementPlan.node.interval > 1 ? agreementPlan.node.interval : ""}{agreementPlan.node.intervalUnit ? agreementPlan.node.intervalUnit : ""} every {this.props.rental.type ? ` ${this.props.rental.type.chargeFrequency} ${this.props.rental.type.chargeFrequencyUnit}` : '--'}</FormText>
                                                                    {this.state.openModal === "promoPriceEdit" + i && <UpdateAgreementPromoPlanModal agreementPlan={agreementPlan.node} title="Edit Agreement Promo Plan" name="promoPrice" isPercentage={agreementPlan.node.isPercentage} value={agreementPlan.node.promoPrice} target={"base" + i} id="base" type="number"
                                                                        open={this.state.openModal === "promoPriceEdit" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery} />}
                                                                </>
                                                            }
                                                            {agreementPlan.__typename == "AgreementPriceNodeEdge" &&
                                                                <>
                                                                    <Label for={"selectedPlanBasePrice" + i}>Plan Base Price {agreementPlan.node.isActive && <Badge color="primary">Active</Badge>}
                                                                        {agreementPlan && agreementPlan.node && agreementPlan.node.startDate && moment().diff(agreementPlan.node.startDate, 'minutes') < 0 && <span id={"price" + i} className="pl-4">
                                                                            <i onClick={() => this.toggleModal("planBasePriceEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                                        </span>}
                                                                    </Label>
                                                                    <p style={{ "fontSize": "1rem", "marginBottom": "auto" }}><b>{agreementPlan.node.price}</b></p>
                                                                    <FormText className="mt-0">{agreementPlan.node.currency ? agreementPlan.node.currency.code.toUpperCase() : "USD"} per {agreementPlan.node.interval && agreementPlan.node.interval > 1 ? agreementPlan.node.interval : ""}{agreementPlan.node.intervalUnit ? agreementPlan.node.intervalUnit : ""} every
                                                                        {this.props.rental.type ? ` ${this.props.rental.type.chargeFrequency} ${this.props.rental.type.chargeFrequencyUnit}` : '--'}</FormText>
                                                                    {this.state.openModal === "planBasePriceEdit" + i && <UpdateAgreementPlan agreementPlan={agreementPlan.node} title="Edit Agreement Plan Price" name="price" value={agreementPlan.node.price} target={"price" + i} id="price" type="number"
                                                                        open={this.state.openModal === "planBasePriceEdit" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery} />}
                                                                </>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    {agreementPlan.__typename == "AgreementPromoNodeEdge" &&
                                                        <Col xs={4}>
                                                            <FormGroup>
                                                                <Label for={"selectedPromoCycle" + i}>Promo Cycle {agreementPlan.node.isCurrentlyAcive}
                                                                    {agreementPlan && agreementPlan.node && agreementPlan.node.startDate && moment().diff(agreementPlan.node.startDate, 'minutes') < 0 && <span id={"cycle" + i} className="pl-4">
                                                                        <i onClick={() => this.toggleModal("planBaseCycleEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                                    </span>}
                                                                    <p style={{ "fontSize": "1rem", "marginBottom": "auto" }}>
                                                                        <b>
                                                                            {agreementPlan.node.cycle}
                                                                        </b>
                                                                    </p>
                                                                    {this.state.openModal === "planBaseCycleEdit" + i && <UpdateAgreementPromoPlanModal agreementPlan={agreementPlan.node} title="Edit Agreement Plan Cycle" name="cycle" value={agreementPlan.node.cycle} target={"cycle" + i} id="cycle" type="number"
                                                                        open={this.state.openModal === "planBaseCycleEdit" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery} />}
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    <Col xs={4}>
                                                        <FormGroup>
                                                            <Label for={"selectedPlanStartDate" + i}>Start Date</Label>
                                                            {agreementPlan.__typename == "AgreementPriceNodeEdge" &&
                                                                <Input name={"selectedPlanStartDate" + i} disabled={agreementPlan && agreementPlan.node && agreementPlan.node.startDate && moment().diff(agreementPlan.node.startDate, 'minutes') < 0 ? false : true} value={agreementPlan.node.startDate ? moment().tz("America/New_York").format(agreementPlan.node.startDate) : ""} onChange={(e) => this.updateAgreementPlan("edit", agreementPlan.node, e)} id={agreementPlan.node.id} min={moment().add(1, 'd').tz("America/New_York").format(moment.HTML5_FMT.DATE)} type="date" />
                                                            }
                                                            {agreementPlan.__typename == "AgreementPromoNodeEdge" &&
                                                                <>
                                                                    <span id={"startDate" + i} className="pl-4">
                                                                        <i onClick={() => this.toggleModal("planStartDateEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                                    </span>
                                                                    {this.state.openModal === "planStartDateEdit" + i && <UpdateAgreementPromoPlanModal agreementPlan={agreementPlan.node} title="Edit Agreement Promo Start Date" name="startDate" value={agreementPlan.node.startDate} target={"startDate" + i} id="startDate" type="date"
                                                                        open={this.state.openModal === "planStartDateEdit" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery} />}
                                                                    <Input
                                                                        name={"selectedPromoStartDate" + i}
                                                                        disabled={true}
                                                                        value={
                                                                            agreementPlan.node.startDate
                                                                                ? moment().tz("America/New_York").format(agreementPlan.node.startDate)
                                                                                : ""
                                                                        }
                                                                        id={agreementPlan.node.id}
                                                                        type="date"
                                                                    />
                                                                </>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    {agreementPlan.__typename == "AgreementPromoNodeEdge" &&
                                                        <Col xs={4}>
                                                            <FormGroup>
                                                                <Label for={"selectedPromoExpirationDate" + i}>Expiration Date</Label>
                                                                <Input
                                                                    name={"selectedPromoExpirationDate" + i}
                                                                    disabled={true}
                                                                    value={
                                                                        agreementPlan.node.expirationDate
                                                                            ? moment().tz("America/New_York").format(agreementPlan.node.expirationDate)
                                                                            : ""
                                                                    }
                                                                    onChange={(e) => this.updateAgreementPromoPlan("edit", agreementPlan.node, e)}
                                                                    id={agreementPlan.node.id}
                                                                    type="date"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    }

                                                    <Col xs={4}>
                                                        <FormGroup className="mb-0">
                                                            <Label for={"selectedPlanCreatedBy" + i} className="mb-0 pb-0">Created By</Label>
                                                            <p>{agreementPlan.node.createdBy ? agreementPlan.node.createdBy.firstName ? agreementPlan.node.createdBy.firstName + " " + agreementPlan.node.createdBy.lastName : agreementPlan.node.createdBy.username : "--"} </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <FormGroup className="mb-0">
                                                            <Label for={"selectedPlanupdatedBy" + i} className="mb-0 pb-0">Updated By</Label>
                                                            <p>{agreementPlan.node.updatedBy ? agreementPlan.node.updatedBy.firstName ? agreementPlan.node.updatedBy.firstName + " " + agreementPlan.node.updatedBy.lastName : agreementPlan.node.updatedBy.username : "--"} </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-3">
                                                    <Col xs={10}>
                                                        {agreementPlan.node.tierType && agreementPlan.node.tiers && agreementPlan.node.tiers.edges && agreementPlan.node.tiers.edges.length > 0 ?
                                                            <FormGroup className="plan-tiers">
                                                                <Label>Tiers {agreementPlan.node.tierType ? " - " + agreementPlan.node.tierType : ""}</Label>
                                                                {agreementPlan.node.tiers && agreementPlan.node.tiers.edges.length > 0 && agreementPlan.node.tiers.edges.map((tier, j) =>
                                                                    <CardText>{"$" + tier.node.price + " - " + tier.node.upperLimit + tier.node.upperLimitUnit}</CardText>
                                                                )}
                                                            </FormGroup>
                                                            : ""}
                                                    </Col>
                                                    {agreementPlan.__typename == "AgreementPromoNodeEdge" &&
                                                        <Col xs={4}>
                                                            {agreementPlan && agreementPlan.node && agreementPlan.node.startDate && moment().diff(agreementPlan.node.startDate, 'minutes') < 0 &&
                                                                <i className="fa fa-circle-o remove-plan" onClick={(e) => this.updateAgreementPromoPlan("remove", agreementPlan.node, e)}>&nbsp;&nbsp;<span>Remove</span></i>
                                                            }
                                                        </Col>
                                                    }

                                                    {agreementPlan.__typename == "AgreementPriceNodeEdge" &&
                                                        <Col xs={4}>
                                                            {agreementPlan && agreementPlan.node && agreementPlan.node.startDate && moment().diff(agreementPlan.node.startDate, 'minutes') < 0 &&
                                                                <i className="fa fa-circle-o remove-plan" onClick={(e) => this.updateAgreementPlan("remove", agreementPlan.node, e)}>&nbsp;&nbsp;<span>Remove</span></i>
                                                            }
                                                        </Col>
                                                    }

                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ) || <Col className="p-5 text-center text-danger" xs={12}><p>No plan has been selected for this agreement!</p></Col>}

                            </ModalBody>
                        </>}
                    <ModalFooter>
                        {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button className="float-right" outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(RentalDetailQuery, {
        options: ({ rental }) => ({ variables: { id: rental.id } }),
        props({ data: { rental, loading, fetchMore, variables } }) {
            return {
                rental, loading, variables,
                refetchReservationQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return fetchMore({
                        query: RentalDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return {
                                rental: fetchMoreResult.rental
                            }
                        },
                    })
                }
            }
        }
    }),
)(NewAgreementPrice)
