import React, { Component } from 'react'
import { withApollo, compose } from "react-apollo/index"
import moment from "moment"
import { PreferredDateTimeContext } from "../../Context/PreferredDateTimeContext"
import './TransactionListNew.css'
import "../../Drivers/Driver/Detail/DetailsNew/DriverDetailNew.css"
import TransactionListNew from './TransactionListNew'
import TransactionListFilters from "./TransactionListFilters"
import ManagePreferenceHOC from "../../Material/ManagePreferenceHOC"

const tableHeaders = [
    { id: "id", name: "ID", sortable: false, visible: true, showInPicker: false, positonIsFixed: true },
    { id: "agreementCarId", name: "Car ID", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "chargeType", name: "Type", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "amount", name: "Amount($)", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "balance", name: "Balance", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "date_added", name: "Date Added", sortable: true, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "event_datetime", name: "Event Date", sortable: true, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "due_date", name: "Due Date", sortable: true, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "createdBy", name: "Created By", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
]

class TransactionListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: {
                includeUpdates: false,
                ignoreUpdatesExceptChargeIds: ["Q2hhcmdlVHlwZU5vZGU6Mg=="],
            },
            orderBy: ["-date_added"],
            refetchTransactions: false,
            selectedTransactions: [],
            error: "",
            loading: false,
            dateFilterType: "Event Datetime",
            aggregatedInfo: {}
        }
    }

    updateOrder = (string) => {
        let currentOrder = this.state.orderBy || []
        let newOrder
        if (currentOrder[0] === string) {
            newOrder = [`-${string}`]
        } else if (currentOrder[0] === `-${string}`) {
            newOrder = []
        } else {
            newOrder = [string]
        }
        this.setState({ orderBy: newOrder })
    };

    render() {
        return (
            <div className="tab-container">
                <TransactionListFilters
                    activeTab={this.props.activeTab}
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    defaultFilters={this.state.filterValues}
                    driverId={this.props.driverId} {...this.props}
                    refetchQuery={() => this.setState({ refetchTransactions: !this.state.refetchTransactions })}
                    refetchDriver={this.props.refetchQuery}
                    tableColConfig={this.state.tableColConfig}
                    setColumns={(tableColConfig) => this.setState({ tableColConfig })}
                    selectedTransactions={this.state.selectedTransactions}
                    resetSelectedTransactions={() => this.setState({ selectedTransactions: [], aggregatedInfo: {} })}
                    aggregatedInfo={this.state.aggregatedInfo}
                    currentUser={this.props.currentUser}
                    columnConfig={this.props.columnConfig}
                    preferenceType={"transaction"}
                    refetchPreferences={this.props.refetchPreferences}
                    dateFilterType={this.state.dateFilterType}
                    updateDateFilterType={(dateFilterType) => this.setState({ dateFilterType })}
                />
                <PreferredDateTimeContext.Consumer>
                    {({ preferredDateTimeInfo }) => (
                        this.props.driverId && <TransactionListNew
                            key={String(this.state.refetchTransactions)}
                            driverId={this.props.driverId}
                            columnConfig={this.props.columnConfig}
                            transactionIds={this.props.transactionIds ? this.props.transactionIds : null}
                            setSelectedTransactions={(selectedTransactions) => this.setState({ selectedTransactions })}
                            selectedTransactions={this.state.selectedTransactions}
                            orderBy={this.state.orderBy}
                            updateOrder={this.updateOrder}
                            chargeTypeIds={this.state.filterValues && this.state.filterValues.chargeTypeIds ? this.state.filterValues.chargeTypeIds : null}
                            notes={this.state.filterValues && this.state.filterValues.notes ? this.state.filterValues.notes : null}
                            groupTypeIds={this.state.filterValues && this.state.filterValues.groupTypeIds ? this.state.filterValues.groupTypeIds : null}
                            includeUpdates={this.state.filterValues && this.state.filterValues.includeUpdates !== null ? this.state.filterValues.includeUpdates : null}
                            ignoreUpdatesExceptChargeIds={this.state.filterValues && this.state.filterValues.ignoreUpdatesExceptChargeIds ? this.state.filterValues.ignoreUpdatesExceptChargeIds : null}
                            includeDeleted={this.state.filterValues && this.state.filterValues.includeDeleted !== null ? this.state.filterValues.includeDeleted : null}
                            paymentPlanCompatible={this.state.filterValues && this.state.filterValues.paymentPlanCompatible !== null ? this.state.filterValues.paymentPlanCompatible : null}
                            minAmount={this.state.filterValues && this.state.filterValues.minAmount ? this.state.filterValues.minAmount : null}
                            maxAmount={this.state.filterValues && this.state.filterValues.maxAmount ? this.state.filterValues.maxAmount : null}
                            eventDatetimeGte={this.state.dateFilterType === "Event Datetime" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz(preferredDateTimeInfo.timezone).format("YYYY-MM-DD") : null}
                            eventDatetimeLte={this.state.dateFilterType === "Event Datetime" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz(preferredDateTimeInfo.timezone).format("YYYY-MM-DD") : null}
                            dateAddedGte={this.state.dateFilterType === "Date Added" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz(preferredDateTimeInfo.timezone).format("YYYY-MM-DD") : null}
                            dateAddedLte={this.state.dateFilterType === "Date Added" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz(preferredDateTimeInfo.timezone).format("YYYY-MM-DD") : null}
                            eventEndDate={this.state.filterValues && this.state.filterValues.endDate ? this.state.filterValues.endDate : null}
                            setAggregatedInfo={(aggregatedInfo) => this.setState({ aggregatedInfo })}
                            aggregatedInfo={this.state.aggregatedInfo}
                            refetchQuery={() => { this.props.refetchQuery() }}
                            currentUser={this.props.currentUser}
                        />
                    )}
                </PreferredDateTimeContext.Consumer>
            </div>
        )
    }
}

export default compose(
    withApollo,
    ManagePreferenceHOC("transaction", tableHeaders)
)(TransactionListContainer)
