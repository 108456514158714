import gql from "graphql-tag"
import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Badge, Table, UncontrolledTooltip, Button } from 'reactstrap'
import DocumentModal from '../../Material/DocumentModal/DocumentModal'
import Languages from "../../Material/Language.json"
import MassMessageModal from "../../Material/MassMessageModal"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import datetimeToString from "../../Functions/datetimeToString"
import "../../Material/ListingHeader.css"
import "../../index-dark.css"
import toTitleCase from "../../Functions/toTitleCase"
import renderEnumToInt from "../../Functions/renderEnumToInt"
import PaymentPlanInfo from "../../Billing/Transactions/PaymentPlanInfo"

const AllPaymentPlans = gql`query AllPaymentPlans($cursor: String,$first:Int!, $status:String, $searchTerm:String, $orderBy: [String]) {
    allPaymentPlans(first: $first,after: $cursor,status: $status, searchTerm:$searchTerm, orderBy:$orderBy){
        edges {
            node {
                id
                pk
                dateAdded
                paydownAmount
                paydownFrequency
                paydownOriginalBalance
                paydownStartDate
                paydownNotes
                reason
                status 
                addedBy {
                    id
                    username
                }
                driver {
                    id
                    name
                    phone
                    email
                    dmvLicense
                    tlcLicense
                    preferredLanguage    
                    branch{
                        id
                        name
                    }
                }
            }
        }
        pageInfo {
            length
            hasNextPage
            startCursor
            endCursor
        }
    } 
}`


const tableHeaders = [
    { id: "date_added", name: "Date Added", sortable: true, views: true },
    { id: "diver__id", name: "Driver Info", sortable: false, views: true },
    { id: "diver__branch", name: "Branch", views: true },
    { id: "paydown_start_date", name: "Paydown Start Date", sortable: true, views: true },
    { id: "paydown_original_balance", name: "Paydown Original Balance", sortable: false, views: true },
    { id: "paydown_frequency", name: "Paydown Frequency", sortable: false, views: true },
    { id: "paydown_amount", name: "Paydown Amount", sortable: false, views: true },
    { id: "status", name: "Status", sortable: false, views: true },
    { id: "added_by", name: "Added By", sortable: false, views: true },
    { id: "action", name: "Action", sortable: false, views: true },
]

class PaymentPlanList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }


    renderLangCode(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }

    render() {
        let allPaymentPlans = this.props.allPaymentPlans
        return (
            <>
                <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={allPaymentPlans && allPaymentPlans.edges.length} pageInfo={allPaymentPlans && allPaymentPlans.pageInfo} loadMoreEntries={allPaymentPlans && allPaymentPlans.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchallPaymentPlansQuery}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                {tableHeaders.map(tableHeader => {
                                    return tableHeader.sortable ?
                                        <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                        <th key={tableHeader.id}>{tableHeader.name}</th>
                                }
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {allPaymentPlans && allPaymentPlans.edges && allPaymentPlans.edges.length > 0 ? allPaymentPlans.edges.map((paymentPlan, i) => <tr key={paymentPlan.node.id} className={paymentPlan.node && paymentPlan.node.inOffice && "driver-in-office-row"}>
                                <td scope="row">
                                    {<span className="listing-counter">{i + 1}</span>}
                                </td>
                                <td>
                                    <small>
                                        <DatetimeRenderer datetime={paymentPlan.node.dateAdded} placement={"left"} />
                                        <br />
                                        <i className="fa fa-history" aria-hidden="true"></i>&nbsp;&nbsp;{datetimeToString(paymentPlan.node.dateAdded)}
                                    </small>
                                </td>
                                <td>
                                    {this.state.openModal === "MassMessageModal" + i && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal" + i)} open={this.state.openModal === "MassMessageModal" + i} drivers={[paymentPlan.node.driver.id]} />}
                                    {this.state.openModal === "driverDocuments" + i && <DocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "driverDocuments" + i} objectType="driver" id={paymentPlan.node.driver.id} driverId={paymentPlan.node.driver.id} currentUser={this.props.currentUser} />}
                                    <div className="driver-detail-block">
                                        {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"driverInfo" + i} placement="right" autohide={false} className="bos-custom-tooltip">
                                            <label>{paymentPlan.node.driver.name}</label>
                                            <span className="bos-custom-tooltip-header">
                                                <i className="fa fa-at" aria-hidden="true"></i>&nbsp;&nbsp;{paymentPlan.node.driver.email || "--"}<br />
                                                <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{paymentPlan.node.driver.phone || "--"}<br />
                                            </span>
                                            <p className="bos-custom-tooltip-body">
                                                <b>Driver License:</b> {paymentPlan.node.driver.dmvLicense || "--"} <br />
                                                {paymentPlan.node.driver.isTlc && paymentPlan.node.driver.tlcLicense && <><b>TLC License:</b> {paymentPlan.node.driver.tlcLicense}<br /></>}
                                                <b>Preferred Language:</b> {paymentPlan.node.driver.preferredLanguage ? this.renderLangCode(paymentPlan.node.driver.preferredLanguage) : "--"}<br />
                                            </p>
                                            <span className="bos-custom-tooltip-footer">
                                                {<><Button onClick={() => this.toggleModal("MassMessageModal" + i)}><i className="fa fa-comment-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                {<><Button onClick={() => this.toggleModal("driverDocuments" + i)}><i className="fa fa-folder-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                            </span>
                                        </UncontrolledTooltip>}
                                        <div className="d-flex" id={"driverInfo" + i}>
                                            <span>
                                                <small>
                                                    {paymentPlan.node.driver.dmvLicense || "--"}
                                                </small>
                                                <br />
                                                <a className="bos-custom-link" href={'/driver/' + paymentPlan.node.driver.id} target="_blank">
                                                    {paymentPlan.node.driver.name.trim() ? paymentPlan.node.driver.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <Badge className='bos-badge-blue bos-badge-small' >{paymentPlan.node.driver.branch && paymentPlan.node.driver.branch.name ? paymentPlan.node.driver.branch.name : "No Branch Selected"}</Badge>
                                </td>
                                <td>
                                    <DatetimeRenderer datetime={paymentPlan.node.paydownStartDate} placement={"left"} />
                                </td>
                                <td>
                                    {"$" + paymentPlan.node.paydownOriginalBalance}
                                </td>
                                <td>
                                    {paymentPlan.node.paydownFrequency}
                                </td>
                                <td>
                                    {"$" + paymentPlan.node.paydownAmount}
                                </td>
                                <td>
                                    <Badge style={{ background: renderEnumToInt(paymentPlan.node.status) === 1 ? "green" : "red" }}>{renderEnumToInt(paymentPlan.node.status) === 1 ? "ACTIVE" : "INACTIVE"}</Badge>
                                </td>
                                <td>
                                    {paymentPlan.node.addedBy ? toTitleCase(paymentPlan.node.addedBy.username) : "--"}
                                </td>
                                <td>
                                    <i id="editCarDeal1" class="fa fa-pencil-square-o" aria-hidden="true" onClick={() => this.toggleModal("PaymentPlanInfo" + paymentPlan.node.pk)} ></i>
                                    {this.state.openModal === "PaymentPlanInfo" + paymentPlan.node.pk &&
                                        <PaymentPlanInfo handleClose={() => this.toggleModal("")} open={this.state.openModal === "PaymentPlanInfo" + paymentPlan.node.pk} id={paymentPlan.node.id} />
                                    }
                                </td>
                            </tr>) :
                                this.props.loading ? <tr><td colSpan={7}>Loading Payment Plans List...</td></tr>
                                    : <tr>
                                        <th scope="row">&nbsp;</th>
                                        <td colSpan={7}>No Payment Plan Found!</td>
                                    </tr>}
                        </tbody>
                    </Table>
                </PrimaryListingContainer >
            </>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllPaymentPlans, {
        options: ({ searchTerm, status, orderBy }) => ({
            variables: {
                searchTerm,
                status,
                orderBy,
                first: 30,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allPaymentPlans, fetchMore, searchTerm, variables, refetch } }) {
            return {
                loading,
                allPaymentPlans,
                variables,
                refetch,
                refetchallPaymentPlansQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allPaymentPlans && allPaymentPlans.edges ? allPaymentPlans.edges.length : 30
                    return fetchMore({
                        query: AllPaymentPlans,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allPaymentPlans.edges
                            const pageInfo = fetchMoreResult.allPaymentPlans.pageInfo
                            return {
                                allPaymentPlans: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allPaymentPlans.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllPaymentPlans,
                        variables: {
                            cursor: allPaymentPlans.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allPaymentPlans.edges
                            const pageInfo = fetchMoreResult.allPaymentPlans.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allPaymentPlans: {
                                    edges: [...previousResult.allPaymentPlans.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allPaymentPlans.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),

)(PaymentPlanList)
