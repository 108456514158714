import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Table, UncontrolledTooltip, Popover, PopoverBody, PopoverHeader, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as ReserveCar } from '../assets/images/icons/reserve-car.svg'
import { EnumQuery } from '../Functions/querys'
import toTitleCase from '../Functions/toTitleCase'
import MassMessageModal from '../Material/MassMessageModal'
import CarModelGroupIcon from './CarModelGroupIcon'
import ReservationModalNew from "../Reservations/Modals/ReservationModalNew"
import DriverNotes from '../ApplicationsNew/DriverNotes'
import UpdateReturnInfo from '../Cars/CarListNew/UpdateReturnInfo'
import ScheduleReturnForm from '../Material/ScheduleReturnForm'
import CarPricingModal from './CarPricingModal'
import gql from 'graphql-tag'
import moment from 'moment'
import './ReturnsList.css'
import RepAssignment from "../Material/RepAssignment"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import "../Material/ListingHeader.css"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import { ReactComponent as InOffice } from "../assets/images/icons/in-office.svg"

const AllReturnsQuery = gql`query ReturnsQuery($cursor: String,$first:Int!, $searchTerm: String, $orderBy: [String], $returnStage: [Int], $agreementStage: Int, $scheduledDateGte:Date, $scheduledDateLte:Date,$rentalEndDateGte:Date, $rentalEndDateLte:Date, $dateAddedGte: Date, $dateAddedLte: Date, $driverStage: [Int], $agreementTypes: [ID], $returnType: String, $returnCategories: [String], $inOffice: Boolean, $loadPricing: Boolean!) {
    returns: allReturns(first:$first, orderBy:$orderBy, after: $cursor, searchTerm:$searchTerm, returnStage:$returnStage, agreementStage:$agreementStage, scheduledDateGte:$scheduledDateGte, scheduledDateLte:$scheduledDateLte,rentalEndDateGte:$rentalEndDateGte, rentalEndDateLte:$rentalEndDateLte, dateAddedGte: $dateAddedGte, dateAddedLte: $dateAddedLte, driverStage: $driverStage, agreementTypes: $agreementTypes, returnType: $returnType, returnCategories: $returnCategories, inOffice: $inOffice ){
      edges{
        cursor
        node{
            id
            returnType
            returnCategory
            notes
            stage
            driverNotes
            scheduledDate
            representative{
                id
                username
                firstName
                lastName
            }
            agreement{
                id
                endDate
                branch{
                    id
                    name
                }
                car{
                    id
                    pk
                    branch{
                        id
                    }
                    hasOpenIssues
                    year
                    carModel{
                        id
                        make
                        name
                        series
                        groupType{
                            id
                            name
                        }
                    }
                    color
                    notes
                    currentAgreement{
                        id
                    }
                    carReservation(status:"Open"){
                        edges{
                            node {
                                id
                            }
                        }
                    }
                    TLCWeeklyPrice: prices(isDefault:true, isActive:true, agreementType_Name_Iexact:"TLC Weekly", orderBy:"-id") @include(if: $loadPricing){
                        edges {
                            node {
                                id
                                title
                                price
                                interval
                                intervalUnit
                                minimumPrice
                                maximumPrice
                            }
                        }
                    }
                    prices(isActive:true, orderBy:"-id") @include(if: $loadPricing){
                        edges {
                            node {
                                id
                                title
                                price
                                isDefault
                                interval
                                intervalUnit
                                minimumPrice
                                maximumPrice
                            }
                        }
                    }
                }
                driver{
                    name
                    id
                    pk
                    tlcLicense
                    notes
                    stage
                    inOffice
                    assignedReps{
                        edges{
                            node{
                                id 
                                username
                                firstName
                                lastName
                            }
                        }
                    }
                }
            }
        }
      }
      pageInfo{
          endCursor
          hasNextPage
      }
    } 
}`

const tableHeaders = [
    { id: "id", name: "Driver Info", sortable: false, excludeViews: [""] },
    { id: "id", name: "Car Info", sortable: true, excludeViews: [""] },
    { id: "scheduled_date", name: "Return Info", sortable: true, excludeViews: [""] },
    { id: "rental_end_date", name: "Rental End Date", sortable: false, excludeViews: ["Available Cars", "All Returns"] },
    { id: "notes", name: "Return Notes", sortable: false, excludeViews: [""] },
    { id: "assignedTo", name: "Assigned Reps", sortable: false, excludeViews: [""] },
    { id: "weekly_charge", name: "Rental Pricing", sortable: false, excludeViews: ["Awaiting To Closeout", "All Returns"] },
    { id: "action", name: "Actions", sortable: false, excludeViews: [""] }
]

class ReturningCarsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            selectAll: false,
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    toggleSelectAll = () => {
        if (this.props.returns && this.props.returns.edges && this.props.returns.edges.length > 0 && !this.state.selectAll) {
            let selection = this.props.returns.edges.map(item => item.node.agreement.driver.id)
            this.props.updateDriversSelection(selection)
            this.setState({ selectAll: true })
        } else {
            this.props.updateDriversSelection([])
            this.setState({ selectAll: false })
        }
    }
    getCarReturnState = (state) => {
        if (!state) { return "------" }
        let sobj = this.props.carReturnStates.states.filter(s => s.name === state)
        return sobj.length ? sobj[0].description : "------"
    }
    render() {
        return (<>
            <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.returns && this.props.returns.edges.length} pageInfo={this.props.returns && this.props.returns.pageInfo} loadMoreEntries={this.props.returns && this.props.returns.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                <Table responsive className="returning-cars-table">
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAll} className="text-center" style={{ cursor: "pointer" }}> {this.state.selectAll ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {tableHeaders.filter(header => !header.excludeViews.includes(this.props.activeTab)).map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.returns && this.props.returns.edges && this.props.returns.edges.length > 0 ? this.props.returns.edges.map((carReturn, i) =>
                            <tr key={carReturn.node.id} className={carReturn.node.agreement && carReturn.node.agreement.driver.inOffice && "driver-in-office-row"}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {!this.props.selectedDrivers.includes(carReturn.node.agreement.driver.id) && <span className="listing-counter">{i + 1}</span>}
                                    <span className="listing-checkbox" onClick={() => this.props.updateDriversSelection(carReturn.node.agreement.driver.id)}>
                                        {this.props.selectedDrivers.includes(carReturn.node.agreement.driver.id) ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                    </span>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <span>
                                            <small>{carReturn.node.agreement.driver.tlcLicense ? carReturn.node.agreement.driver.tlcLicense : "--"}&nbsp;</small>
                                            <br />
                                            <strong>
                                                <a className="bos-custom-link" href={'/driver/' + carReturn.node.agreement.driver.id} target="_blank">
                                                    <strong>{carReturn.node.agreement.driver.name}</strong>
                                                </a>
                                                {carReturn.node.agreement.driver.stage == 6 && <>
                                                    <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-success" ></i>
                                                    <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Driver</UncontrolledTooltip>
                                                </>} {carReturn.node.agreement.driver && carReturn.node.agreement.driver.inOffice &&
                                                    <>
                                                        <InOffice id={"inOffice" + String(carReturn.node.agreement.driver.pk)} width={25} height={25} fill="#198754" />
                                                        <UncontrolledTooltip target={"inOffice" + String(carReturn.node.agreement.driver.pk)}>Driver in Office</UncontrolledTooltip>
                                                    </>
                                                }
                                            </strong>
                                        </span>
                                    </div>
                                </td>
                                <td className="py-3">
                                    <div className="cars-wrapper">
                                        <div className="car-id-detail">
                                            <CarModelGroupIcon carGroup={carReturn.node.agreement.car.carModel.groupType ? carReturn.node.agreement.car.carModel.groupType.name : null} carColor={carReturn.node.agreement.car.color} id={carReturn.node.agreement.car.pk} key={i + 1} />
                                            <br />
                                            <span>{carReturn.node.agreement.car.pk}</span>
                                        </div>
                                        <div className="car-name-years">
                                            <span>
                                                <small>
                                                    {carReturn.node.agreement.car.year} | {carReturn.node.agreement.car.color} {carReturn.node.agreement.car.carModel.groupType ? "| " + carReturn.node.agreement.car.carModel.groupType.name : ""}
                                                </small>
                                            </span>
                                            <div className="car-name">
                                                <a className="bos-custom-link" href={'/car/' + carReturn.node.agreement.car.pk} target="_blank">
                                                    <strong>{toTitleCase(carReturn.node.agreement.car.carModel.make)} {toTitleCase(carReturn.node.agreement.car.carModel.name)}</strong>
                                                </a>&nbsp;
                                                <small>{carReturn.node.agreement.car.carModel.series ? `(${carReturn.node.agreement.car.carModel.series})` : ''}</small>
                                                {(carReturn.node.agreement.car.stage == 10 || (carReturn.node.agreement.car.currentAgreement && carReturn.node.agreement.car.currentAgreement.id)) && <>
                                                    <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-success" ></i>
                                                    <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Car</UncontrolledTooltip>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="py-3">
                                    <span>
                                        <strong>{carReturn.node.returnType}</strong> <small>({toTitleCase(carReturn.node.returnCategory)})</small>
                                        <br />
                                        <small>
                                            <UncontrolledTooltip target={"scheduledDate" + i}>Scheduled Date</UncontrolledTooltip>
                                            <span id={"scheduledDate" + i} className={carReturn.node.scheduledDate && moment().diff(moment(carReturn.node.scheduledDate), 'days') > 0 ? "text-danger" : ""}>{carReturn.node.scheduledDate ? <DatetimeRenderer datetime={carReturn.node.scheduledDate} placement={"left"} /> : "--"} </span>
                                            <UncontrolledTooltip target={"returnStage" + i}>Return Stage</UncontrolledTooltip>
                                            | <span id={"returnStage" + i}> {carReturn.node.stage ? this.getCarReturnState(carReturn.node.stage) : "--"} </span>
                                            <UncontrolledTooltip target={"username" + i}>Created By</UncontrolledTooltip>
                                            | <span id={"username" + i}> {carReturn.node.representative ? carReturn.node.representative.firstName ? carReturn.node.representative.firstName + " " + carReturn.node.representative.lastName : carReturn.node.representative.username : "--"}</span>
                                        </small>
                                        <br />
                                        <Badge className='branch-badge'>{carReturn.node.agreement && carReturn.node.agreement.branch && carReturn.node.agreement.branch.name ? carReturn.node.agreement.branch.name : "No Branch Seleted"}</Badge>
                                    </span>
                                </td>
                                {this.props.activeTab === "Awaiting To Closeout" &&
                                    <td>
                                        {carReturn.node.agreement && carReturn.node.agreement.endDate ?
                                            <DatetimeRenderer datetime={carReturn.node.agreement.endDate} placement={"left"} />
                                            : "--"
                                        }
                                    </td>
                                }
                                <td className="shortNotes">
                                    <div className="notes">
                                        {this.state.openModal === "returnNotesEdit" + i &&
                                            <UpdateReturnInfo carReturn={carReturn.node} title="Edit Return Notes" name="notes" value={carReturn.node.notes} target={"returnNotes" + i} id="returnNotes" type="text"
                                                open={this.state.openModal === "returnNotesEdit" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} placement={"left"}
                                            />
                                        }
                                        {this.state.openModal === "returnNotesHistory" + i &&
                                            <DriverNotes driver={carReturn.node.agreement.driver} noteType="RETURN" title="Return Notes" objectId={carReturn.node.id} target={"returnNotes" + i} open={this.state.openModal === "returnNotesHistory" + i} handleClose={() => this.toggleModal("")} placement={"left"} />
                                        }
                                        <span id={"returnNotes" + i}>
                                            <small className="note-text">
                                                {carReturn.node.notes ? carReturn.node.notes : "--"}
                                            </small>
                                            <br />
                                            <i onClick={() => this.toggleModal("returnNotesEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                            <i onClick={() => this.toggleModal("returnNotesHistory" + i)} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                            <UncontrolledTooltip placement="top" target={"returnNotes" + i}>{carReturn.node.notes ? carReturn.node.notes : "--"}</UncontrolledTooltip>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <span className='pr-3 align-self-center'>
                                            <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleModal("rep" + i)} id={"rep" + i}></i>
                                            {this.state.openModal === "rep" + i && <Popover isOpen={this.state.openModal === "rep" + i} placement="bottom" target={"rep" + i}>
                                                <PopoverHeader onClick={() => this.toggleModal("")}>Assign Rep <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                                                <PopoverBody style={{ minWidth: "200px" }}>
                                                    <RepAssignment handleClose={() => this.toggleModal("rep" + i)} driver={carReturn.node.agreement.driver} defaultValue={carReturn.node.agreement.driver.assignedReps.edges} refetchDrivers={this.props.refetchQuery} groups={["Sales Team"]} />
                                                </PopoverBody>
                                            </Popover>}
                                        </span>
                                        <span className="pl-3" style={{ borderLeft: "1px solid #e1e1e1" }}>
                                            {carReturn.node.agreement.driver.assignedReps && carReturn.node.agreement.driver.assignedReps.edges && carReturn.node.agreement.driver.assignedReps.edges.length > 0 ? carReturn.node.agreement.driver.assignedReps.edges.map(rep =>
                                                <span>{rep.node.name}</span>) : "--"
                                            }
                                        </span>
                                    </div>
                                </td>
                                {this.props.activeTab === "Available Cars" &&
                                    <td>
                                        <div className="view-prices">
                                            <CarPricingModal key={i + 1} prices={carReturn.node.agreement.car.prices} carPk={carReturn.node.agreement.car.pk} title={"All Rental Pricing Templates"} />
                                            <span className="car-pricing-default">
                                                {carReturn.node.agreement.car.TLCWeeklyPrice && carReturn.node.agreement.car.TLCWeeklyPrice.edges && carReturn.node.agreement.car.TLCWeeklyPrice.edges.length > 0 && carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.price ?
                                                    ("$" + carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.price + "/- " + (carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.interval > 1 ? carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.interval + " " : "") + carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.intervalUnit) :
                                                    carReturn.node.agreement.car.prices && carReturn.node.agreement.car.prices.edges && carReturn.node.agreement.car.prices.edges.length > 0 ?
                                                        (carReturn.node.agreement.car.prices.edges.filter(item => item.node.isDefault).map(price => "$" + price.node.price + "/- " + (price.node.interval > 1 ? price.node.interval + " " : "") + price.node.intervalUnit)[0] || "") : "No Price Found!"}
                                                <br /><small>{carReturn.node.agreement.car.TLCWeeklyPrice && carReturn.node.agreement.car.TLCWeeklyPrice.edges && carReturn.node.agreement.car.TLCWeeklyPrice.edges.length > 0 && carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.minimumPrice ?
                                                    ("$" + carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.minimumPrice + (carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.maximumPrice ? " - $" + carReturn.node.agreement.car.TLCWeeklyPrice.edges[0].node.maximumPrice : ""))
                                                    : carReturn.node.agreement.car.prices && carReturn.node.agreement.car.prices.edges && carReturn.node.agreement.car.prices.edges.length > 0 ?
                                                        (carReturn.node.agreement.car.prices.edges.filter(item => item.node.isDefault).map(price => price.node.minimumPrice ?
                                                            "$" + price.node.minimumPrice + (price.node.maximumPrice ? " - $" + price.node.maximumPrice : "") : "")[0] || "") : ""}
                                                </small>
                                            </span>
                                        </div>
                                    </td>
                                }
                                <td>
                                    <ul className="actions pt-3">
                                        {carReturn.node.agreement && carReturn.node.agreement.driver && carReturn.node.agreement.driver.id &&
                                            <li>
                                                {this.state.openModal === "SendMessageTo" + carReturn.node.agreement.driver.id && <MassMessageModal handleClose={() => this.setState({ openModal: null })} open={this.state.openModal === "SendMessageTo" + carReturn.node.agreement.driver.id} drivers={[carReturn.node.agreement.driver.id]} />}
                                                <a onClick={() => this.setState({ openModal: "SendMessageTo" + carReturn.node.agreement.driver.id })}>
                                                    <i className="fa fa-envelope-o" aria-hidden="true" style={{ fontSize: "18px" }}></i>
                                                </a>
                                            </li>
                                        }
                                        <li>
                                            {this.state.openModal === "NewReservationModal" + i && <ReservationModalNew handleClose={() => this.setState({ openModal: null })} open={this.state.openModal === "NewReservationModal" + i} refetchQuery={this.props.refetchQuery} carId={carReturn.node.agreement.car.id} title={"New Reservation for Car " + carReturn.node.agreement.car.pk} />}
                                            {this.state.openModal === "UpdateReservationModal" + i && carReturn.node.agreement && carReturn.node.agreement.car && carReturn.node.agreement.car.carReservation && carReturn.node.agreement.car.carReservation.edges[0] && carReturn.node.agreement.car.carReservation.edges[0].node && carReturn.node.agreement.car.carReservation.edges[0].node.id &&
                                                <ReservationModalNew id={carReturn.node.agreement.car.carReservation.edges[0].node.id} title="Update Reservation" open={this.state.openModal === "UpdateReservationModal" + i} isUpdate={true} handleClose={() => this.toggleModal("")} />
                                            }
                                            <a id={"reserve-car-" + i} onClick={() => this.setState({ openModal: (carReturn.node.agreement.car.carReservation && carReturn.node.agreement.car.carReservation.edges && carReturn.node.agreement.car.carReservation.edges.length > 0 ? "UpdateReservationModal" : "NewReservationModal") + i })}><ReserveCar width={30} fill={carReturn.node.agreement.car.carReservation && carReturn.node.agreement.car.carReservation.edges && carReturn.node.agreement.car.carReservation.edges.length > 0 ? "#28a745" : "#303E67"} /></a>
                                            <UncontrolledTooltip placement="bottom" target={"reserve-car-" + i}>{carReturn.node.agreement.car.carReservation && carReturn.node.agreement.car.carReservation.edges && carReturn.node.agreement.car.carReservation.edges.length > 0 ? "Edit Reservation Info" : "Reserve Car"}</UncontrolledTooltip>
                                        </li>
                                        {carReturn.node.stage !== "A_3" && <li>
                                            <i onClick={() => this.toggleModal("returnEdit" + i)} id={"returnEdit" + i} className={"fa " + (["A_4"].includes(carReturn.node.stage) ? "fa-eye" : "fa-pencil")} style={{ fontSize: "18px" }}></i>
                                            {this.state.openModal === "returnEdit" + i &&
                                                <ScheduleReturnForm
                                                    refetchQuery={() => { this.props.refetchQuery() }}
                                                    handleClose={() => this.toggleModal("")}
                                                    rental={carReturn.node.agreement}
                                                    carReturn={carReturn.node}
                                                    car={carReturn.node.agreement.car}
                                                    open={this.state.openModal === "returnEdit" + i}
                                                    editReturn={true}
                                                    returnStages={this.props.carReturnStates}
                                                    currentUser={this.props.currentUser ? this.props.currentUser : ""}
                                                />}
                                            <UncontrolledTooltip placement="bottom" target={"returnEdit" + i}>{["A_4"].includes(carReturn.node.stage) ? "View Return Info" : "Edit Return Info"}</UncontrolledTooltip>
                                        </li>}
                                    </ul>
                                </td>
                            </tr>
                        ) : <tr>
                            <td colSpan={10}>{this.props.loading ? "Loading..." : "No Return Found!"}</td>
                        </tr>}
                    </tbody>
                </Table>
            </PrimaryListingContainer>

        </>)
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, { props({ data: { carReturnStates } }) { return { carReturnStates } } }),
    graphql(AllReturnsQuery,
        {
            options: ({ searchTerm, orderBy, returnStage, agreementStage, scheduledDateGte, scheduledDateLte, rentalEndDateGte, rentalEndDateLte, dateAddedGte, dateAddedLte, driverStage, returnType, agreementTypes, returnCategories, activeTab, inOffice }) => ({
                variables: { searchTerm, orderBy, returnStage, agreementStage, scheduledDateGte, scheduledDateLte, rentalEndDateGte, rentalEndDateLte, dateAddedGte, dateAddedLte, driverStage, first: 30, returnType, agreementTypes, returnCategories, loadPricing: activeTab && activeTab === "Available Cars" ? true : false, inOffice },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
            }
            ),
            props({ data: { loading, returns, fetchMore, variables } }) {
                return {
                    loading,
                    returns,
                    variables,
                    refetchQuery: () => {
                        //No apollo function that refetches in place with pagination considered so this function instead
                        let currentLength = returns && returns.edges ? returns.edges.length : 30
                        return fetchMore({
                            query: AllReturnsQuery,
                            variables: {
                                ...variables,
                                first: currentLength,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.returns.edges
                                const pageInfo = fetchMoreResult.returns.pageInfo
                                return {
                                    returns: {
                                        edges: [...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        __typename: previousResult.returns.__typename
                                    },
                                }
                            },
                        })
                    },
                    loadMoreEntries: () => {
                        return fetchMore({
                            query: AllReturnsQuery,
                            variables: {
                                cursor: returns.pageInfo.endCursor,
                                ...variables
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.returns.edges
                                const pageInfo = fetchMoreResult.returns.pageInfo

                                return {
                                    returns: {
                                        edges: [...previousResult.returns.edges, ...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        issueCategory: previousResult.issueCategory,
                                        status: previousResult.status,
                                        currentAgreementIsnull: previousResult.currentAgreementIsnull,
                                        dateAdded: previousResult.dateAdded,
                                        __typename: previousResult.returns.__typename
                                    },
                                }
                            },
                        })
                    },
                }
            },
        })
)(ReturningCarsList)
